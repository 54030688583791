import React, { FunctionComponent } from 'react';
import cn from 'classnames';
import ProductMediaDisplay from 'types/enums/ProductMediaDisplay';
import { DefaultProps } from 'types/interfaces/DefaultProps';
import { Media, MediaTypeEnum } from 'types/schema';

import { imageWrapper } from '../styles';

export interface VideoProps extends DefaultProps {
  lazyLoad?: boolean;
  displayType?: ProductMediaDisplay;
  type: MediaTypeEnum;
  url?: string;
}

const Video: FunctionComponent<VideoProps> = (props) => {
  const { url, className, children, displayType = ProductMediaDisplay.Landscape } = props;

  return (
    <div className={cn(imageWrapper(displayType), 'relative overflow-hidden', className)}>
      <div className="imageContainer videoElement flex items-center overflow-hidden rounded-t-16">
        <video
          className="overflow-hidden rounded-t-16"
          controls={true}
          poster="https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-HD.jpg"
          src={url}
        />
      </div>
      {children}
    </div>
  );
};

export default Video;
