import { css } from '@emotion/css';

export const container = css`
  padding-top: 10rem;
  padding-bottom: 9.8rem;
  max-width: 116rem;
`;

export const titleStyle = css`
  max-width: 74rem;
  margin-bottom: 5.6rem;
`;

export const customLinkColor = css`
  color: var(--link-01) !important;
  display: block;
`;

export const pictureContainer = css`
  margin-inline-start: 10rem;
  width: 28rem;
  max-height: 28rem;
  max-width: 28rem;
  @media all and (max-width: 768px) {
    margin-inline-start: 0;
    margin-bottom: 3.2rem;
    max-height: 15rem;
    max-width: 15rem;
  }

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

export const testimoyText = css`
  @media all and (max-width: 768px) {
    min-height: 33.6rem;
  }
`;
