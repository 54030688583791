import React from 'react';
import { css } from '@emotion/css';
import cn from 'classnames';

import { Box, Stack } from '@lyearn/ui';

const imagesWrapper = css`
  max-width: 46rem;
`;

const col = css`
  width: 30.43%;
  margin-inline-end: 4.34%;

  &:last-child {
    margin-inline-end: 0;
  }

  & .imageContainer {
    max-height: 18rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;

    @media all and (max-width: 768px) {
      max-height: 10.7rem;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const centerCol = css`
  padding-top: 8rem;
`;

const GroupedImages: React.FC<{ images: string[] }> = ({ images }) => {
  return (
    <Stack className={imagesWrapper}>
      <Stack className={cn(col)} direction="col">
        <Box className="imageContainer overflow-hidden rounded-16">
          <img alt="hero 1" height="360" src={images[0]} width="280" />
        </Box>
        <Box className="imageContainer overflow-hidden rounded-16">
          <img alt="hero 2" height="360" src={images[1]} width="280" />
        </Box>
      </Stack>
      <Stack className={cn(col, centerCol)} direction="col">
        <Box className="imageContainer overflow-hidden rounded-16">
          <img alt="hero 2" height="360" src={images[2]} width="280" />
        </Box>
        <Box className="imageContainer overflow-hidden rounded-16">
          <img alt="hero 3" height="360" src={images[3]} width="280" />
        </Box>
      </Stack>
      <Stack className={cn(col)} direction="col">
        <Box className="imageContainer overflow-hidden rounded-16">
          <img alt="hero 5" height="360" src={images[4]} width="280" />
        </Box>
        <Box className="imageContainer overflow-hidden rounded-16">
          <img alt="hero 6" height="360" src={images[5]} width="280" />
        </Box>
      </Stack>
    </Stack>
  );
};

export default GroupedImages;
