// export enum LayoutTypes {
//   SimpleLayout = 'SIMPLE_LAYOUT',
// }

export interface LayoutComponent {
  id: string;
  componentRef: string;
  props?: object;
  config?: {
    visibility?: Visibility;
  };
  childComponentRefs?: Array<string>;
  children?: any;
}

export interface LayoutComponentUnflattened extends LayoutComponent {
  id: string;
  props?: any;
  children: any[];
  childComponentRefs?: Array<string>;
  component: any;
}

export interface LayoutChildComponents<T> {
  [key: string]: T;
}

export interface LayoutConfig {
  id: string;
  children: Array<string>;
  type: string;
  components: LayoutChildComponents<LayoutComponent>;
}

export interface LayoutConfigStructured {
  id: string;
  children: Array<string>;
  type: string;
  components: LayoutChildComponents<LayoutComponentUnflattened>;
}

export enum Visibility {
  isAuthenticated = 'IS_AUTHENTICATED',
}
