import React from 'react';
import c from 'classnames';
import { DefaultProps } from 'types/interfaces/DefaultProps';

import { Notepad, PlayCustom, TextContentCustom } from '@lyearn/icons';

import { iconStyle } from './styles';

export interface IconProps extends DefaultProps {
  type: string;
}

/* TODO: used in no longer required (After course page redesign) Curriculum component */
const Icon: React.FC<IconProps> = (props) => {
  const { type, className } = props;
  switch (type) {
    case 'notepad':
      return <Notepad className={className} />;
    case 'textContent':
      return <TextContentCustom className={c(iconStyle, className)} />;
    case 'playFill':
      return <PlayCustom className={c(iconStyle, className)} />;
    default:
      return null;
  }
};

export default Icon;
