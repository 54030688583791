import React, { useCallback } from 'react';
import c from 'classnames';

import { ArrowIosDownward } from '@lyearn/icons';
import {
  Accordion as BaseAccordion,
  AccordionDetails as BaseAccordionDetails,
  AccordionSummary as BaseAccordionSummary,
} from '@lyearn/ui';
import { Typography } from '@lyearn/ui';

import { contentStyle, marginStyle } from './styles';
import { AccordionProps } from './types';

const Accordion = (props: AccordionProps) => {
  const { expanded, summary, details, onChange, id } = props;

  const handleChange = useCallback(() => onChange(id), [onChange, id]);

  return (
    <BaseAccordion
      classes={{
        root: c(
          'group border-b border-interactive-secondary shadow-none hover:bg-bg-secondary z-0',
          marginStyle,
        ),
      }}
      expanded={expanded}
      onChange={handleChange}>
      <BaseAccordionSummary
        className="px-20"
        classes={{
          root: marginStyle,
          content: expanded ? 'my-12' : 'my-24',
        }}
        expandIcon={<ArrowIosDownward className="h-24 w-24 group-hover:text-text-primary" />}>
        <Typography variant={expanded ? 'heading-xs' : 'body-short-01'}>{summary}</Typography>
      </BaseAccordionSummary>
      <BaseAccordionDetails className="px-20 pb-12 pt-0">
        <Typography as="div" color="text-text-secondary" variant="body-long-01">
          <div dangerouslySetInnerHTML={{ __html: details }} className={contentStyle} />
        </Typography>
      </BaseAccordionDetails>
    </BaseAccordion>
  );
};

export default Accordion;
