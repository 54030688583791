import React, { FunctionComponent } from 'react';
import cn from 'classnames';
import transformDuration from 'helpers/transformDuration';
import { DefaultProps } from 'types/interfaces/DefaultProps';

import { ClockOutline } from '@lyearn/icons';
import { Typography } from '@lyearn/ui';

export interface DurationProps extends DefaultProps {
  duration?: number;
}

const Duration: FunctionComponent<DurationProps> = (props) => {
  /* duration is in seconds */
  const { duration, className } = props;

  if (!duration) {
    return <Typography />;
  }

  return (
    <Typography
      as="span"
      className={cn('flex items-center text-text-tertiary', className)}
      variant="heading-xxs">
      <ClockOutline className="h-20 w-20 fill-current me-4" />
      {transformDuration(duration)}
    </Typography>
  );
};

export default Duration;
