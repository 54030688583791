import React from 'react';
import cn from 'classnames';

import { Box, Stack, Typography } from '@lyearn/ui';

import { roleText, userName } from '../styles';
import { Testimonial } from '../types';

type Props = { testimonial: Testimonial; className?: string; fullHeight?: boolean };

const TestimonialItem: React.FC<Props> = ({ testimonial, className, fullHeight = true }) => {
  return (
    <Stack
      className={cn('bg-bg-secondary p-20 xs:mb-12', { 'flex-1': fullHeight }, className)}
      direction="col">
      <Box className={cn('pb-4 text-14 font-bold opacity-80', userName)}>{testimonial.name}</Box>
      <Typography className={cn('mb-16', roleText)} variant="body-short-03">
        {testimonial.role}
      </Typography>
      <Typography color="text-text-info" variant="body-short-02">
        {testimonial.message}
      </Typography>
    </Stack>
  );
};

export default TestimonialItem;
