import React from 'react';
import { createContext } from 'helpers/createContext';
import useUserProducts from 'modules/product/hooks/useUserProducts';
import { UserProduct } from 'modules/product/types';

const PRODUCTS = 'Products';

type ProductsContextValue = {
  products: UserProduct[];
  fetching: boolean;
  isStale?: boolean;
};

type ProductsContextProps = {
  products: UserProduct[];
};
const [ProductsContextProvider, useProductsContext] = createContext<ProductsContextValue>(PRODUCTS);

const _ProductsContextProvider: React.FC<React.PropsWithChildren<ProductsContextProps>> = ({
  products,
  children,
}) => {
  const { products: _products, fetching, isStale } = useUserProducts(products);

  return (
    <ProductsContextProvider fetching={fetching} isStale={isStale} products={_products}>
      {children}
    </ProductsContextProvider>
  );
};

export { _ProductsContextProvider as ProductsContextProvider, useProductsContext };
export default _ProductsContextProvider;
