import { css } from '@emotion/css';

export const wrapperStyle = css`
  max-width: 120rem;
  grid-template-columns: calc(20% - 32px) calc(80% - 32px);
  margin: 0 auto;
  @media (max-width: 768px) {
    grid-template-columns: 100%;
  }
`;

export const sectionHeight = css`
  min-height: 85vh;
  @media (max-width: 768px) {
    min-height: unset;
  }
`;
