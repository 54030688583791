import React from 'react';
import cn from 'classnames';
import { useToggle } from 'hooks/useToggle';

import { Box, Button, Stack } from '@lyearn/ui';

import { buttonLabel, container, overlay } from './styles';

const CollapsibleContent: React.FC<React.PropsWithChildren<{ maxHeight: string }>> = ({
  children,
  maxHeight,
}) => {
  const [expanded, toggleExpanded] = useToggle(false);

  return (
    <Stack alignItems="center" className={cn('relative')} direction="col">
      {!expanded ? <Box className={cn('absolute z-10', overlay)} /> : null}
      <Box className={cn('overflow-hidden', container(expanded, maxHeight))}>{children}</Box>
      <Button
        appearance="secondary"
        className={cn('z-20 mt-40 rounded-4', buttonLabel)}
        variant="outlined"
        onClick={toggleExpanded}>
        Show {expanded ? 'less' : 'more'}
      </Button>
    </Stack>
  );
};

export default CollapsibleContent;
