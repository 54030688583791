import React from 'react';

import { Box } from '@lyearn/ui';

const CardBodyTop: React.FC<React.ComponentProps<typeof Box>> = ({ children, ...rest }) => {
  return (
    <Box className="flex flex-1 flex-col pb-50" {...rest}>
      {children}
    </Box>
  );
};

export default CardBodyTop;
