import { useUser } from 'modules/context/Contexts/User';

import { Visibility } from '../types';

const useCheckVisibility = (visibilityConfig?: Visibility) => {
  const user = useUser();

  if (visibilityConfig === Visibility.isAuthenticated) {
    if (user.id) {
      return true;
    }
    return false;
  }

  return true;
};

export default useCheckVisibility;
