// libs
import React, { useCallback } from 'react';
import { scroller } from 'react-scroll';
import c from 'classnames';
import AccordionGroup from 'components/Accordion/AccordionGroup';
import { HEADER_HEIGHT } from 'components/Header/constants';
import SideNav from 'components/SideNav';
// components
import StickySection from 'components/StickySection';
import { SECTION_HEADING_HEIGHT } from 'components/StickySection/styles';
import useScrollSpy from 'hooks/useScrollSpy';
import groupBy from 'lodash/groupBy';
import useInstanceConfig from 'modules/siteConfig/hooks/useInstanceConfig';
import { Site } from 'types/interfaces/SiteConfig/Site';

import { Box } from '@lyearn/ui';

// styles
import { sectionHeight, wrapperStyle } from './styles';

/**
 * An offset has to be given to the scroll as the title of the section is set to sticky
 * and if not given the scroll will hide the FAQ under the title.
 * Multiplied by 10 as the height is in rem and offset uses pixel.
 */
const SCROLL_OFFSET = -(SECTION_HEADING_HEIGHT * 10);

function getData(faq: Site['faq']) {
  const groupedData = groupBy(faq, 'type');
  const sections = Object.keys(groupedData);
  const navItems = sections.map((key) => ({ id: key, name: key }));

  return { groupedData, sections, navItems };
}

const FAQView = () => {
  const { site } = useInstanceConfig();
  const { groupedData, sections, navItems } = getData(site.faq);
  const activeSection = useScrollSpy(sections, sections[0]);

  const handleClick = useCallback((id: string) => {
    // Scroll to the id of the FAQ Section
    scroller.scrollTo(id, { smooth: true, offset: SCROLL_OFFSET });
  }, []);

  return (
    <Box
      className={c('relative grid justify-center justify-items-center gap-64 px-16', wrapperStyle)}>
      <SideNav
        sticky
        activeId={activeSection}
        className="xs:hidden"
        items={navItems}
        top={HEADER_HEIGHT + SECTION_HEADING_HEIGHT}
        onClick={handleClick}
      />
      <Box className="relative min-h-screen">
        {sections.map((key) => {
          const data = groupedData[key].map((item) => ({
            id: item.id,
            summary: item.question,
            details: item.answer,
          }));

          return (
            <StickySection
              key={key}
              sticky
              className={sectionHeight}
              id={key}
              name={key}
              top={HEADER_HEIGHT}>
              <AccordionGroup data={data} />
            </StickySection>
          );
        })}
      </Box>
    </Box>
  );
};

export default FAQView;
