import { css } from '@emotion/css';

const MAX_WIDTH = '144rem';

export const container = css`
  max-width: ${MAX_WIDTH};
  padding: 30rem 12rem;

  @media all and (max-width: 991px) {
    padding: 12rem 2rem;
  }
`;

export const snackbarRoot = css`
  border-color: rgba(51, 51, 51, 1);
  background: #fff;
`;

export const messageContainer = css`
  width: 37.15%;
  @media all and (max-width: 768px) {
    width: 100%;
  }
`;

export const formContainer = css`
  width: 46.17%;
  @media all and (max-width: 768px) {
    width: 100%;
  }
`;

export const spacer = css`
  min-height: 4.4rem;
`;
