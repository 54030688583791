import React from 'react';
import { PageComponent } from 'types/interfaces/SiteConfig/Pages';

import { Stack } from '@lyearn/ui';

const FlexRow = ({ layoutConfig, children }: { layoutConfig: PageComponent; children: any[] }) => {
  const { props } = layoutConfig;
  const { className = '' } = props;

  return (
    <Stack className={className} direction="row">
      {children}
    </Stack>
  );
};

export default FlexRow;
