import React from 'react';
import cn from 'classnames';
import Carousel from 'components/Carousel';

import { Box, Stack, Typography } from '@lyearn/ui';

import { container, titleStyle } from './styles';
import TestimonialItem from './TestimonialItem';
import { OurStudentsProps } from './types';

const OurStudents = ({ label, title, testimonials }: OurStudentsProps) => {
  return (
    <Stack className={cn('mx-auto px-20', container)} direction="col">
      <Typography className="mb-14" color="text-text-placeholder" variant="label-02">
        {label}
      </Typography>
      <Typography className={cn('heading-xxxl xs:heading-xxl text-text-primary', titleStyle)}>
        {title}
      </Typography>
      <Box className="">
        <Carousel
          autoHeight={true}
          autoplay={{
            delay: 5500,
            disableOnInteraction: true,
          }}
          loop={true}
          pagination={true}
          spaceBetween={40}>
          {testimonials.map((testimonial: TSFixMe, index: number) => (
            <Carousel.Slide key={testimonial.studentName} className="swiper-slide flex-shrink-0">
              <TestimonialItem {...testimonial} key={index} />
            </Carousel.Slide>
          ))}
        </Carousel>
      </Box>
    </Stack>
  );
};

export default OurStudents;
