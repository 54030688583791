import React from 'react';
import cn from 'classnames';

import { Facebook, Instagram, LinkedIn, Twitter, Youtube } from '@lyearn/icons';
import { Box } from '@lyearn/ui';

import { linkedInIcon } from './styles';

const getIcon = (name: string, height: number, width: number) => {
  const className = `h-${height} w-${width}`;
  switch (name) {
    case 'YOUTUBE':
      return (
        <svg
          className={className}
          fill="currentColor"
          height="24"
          viewBox="2 2 20 20"
          width="24"
          xlinkTitle={name.toLowerCase()}>
          <path d="M18.16,4.51a37.7,37.7,0,0,0-12.36,0C3.46,5,2,6.51,2,8.48v7c0,2,1.46,3.49,3.84,4A37.47,37.47,0,0,0,12,20a37.8,37.8,0,0,0,6.18-.51c2.32-.44,3.82-2,3.82-4v-7C22,6.5,20.5,4.94,18.16,4.51Zm-2.9,7.94-5,3a.48.48,0,0,1-.26.07.57.57,0,0,1-.25-.06A.52.52,0,0,1,9.5,15V9a.52.52,0,0,1,.25-.44.54.54,0,0,1,.51,0l5,3a.51.51,0,0,1,0,.86Z" />
        </svg>
      );
    case 'FACEBOOK':
      return <Facebook className={className} xlinkTitle={name.toLowerCase()} />;
    case 'TWITTER':
      return <Twitter className={className} xlinkTitle={name.toLowerCase()} />;
    case 'INSTAGRAM':
      return (
        <Instagram className={className} viewBox="-2 -2 26 26" xlinkTitle={name.toLowerCase()} />
      );
    case 'LINKEDIN':
      return (
        <Box className={cn(className, linkedInIcon, 'rounded-4')}>
          <LinkedIn className={cn(className, 'text-bg-primary')} xlinkTitle={name.toLowerCase()} />
        </Box>
      );
  }
};

const SocialLinks = ({
  socialMediaLinks,
  width = 18,
  height = 18,
  colourClasses = '',
  className = '',
}: TSFixMe) => {
  const links = socialMediaLinks;
  const linksLength = links.length;
  return (
    <>
      {links.map((link: TSFixMe, index: number) => {
        const isLast = linksLength - 1 === index;
        return (
          <a
            key={index}
            className={cn(
              'block',
              colourClasses ?? 'hover:text-bg-accent',
              { 'me-30': !isLast },
              className,
            )}
            href={link.url}
            rel="noreferrer"
            target="_blank"
            title={link.media}>
            {getIcon(link.media, height, width)}
          </a>
        );
      })}
    </>
  );
};

export default SocialLinks;
