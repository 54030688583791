import React from 'react';

import useCheckVisibility from '../hooks/useCheckVisibility';
import { LayoutComponentUnflattened } from '../types';

const LayoutEntry = ({ layoutEntry }: { layoutEntry: LayoutComponentUnflattened }) => {
  const Component = layoutEntry.component;
  const isVisible = useCheckVisibility(layoutEntry.config?.visibility);
  if (!isVisible) {
    return null;
  }

  return (
    <Component layoutConfig={layoutEntry} {...layoutEntry.props}>
      {layoutEntry.children
        ? layoutEntry.children.map((child, index) => (
            <LayoutEntry key={index} layoutEntry={child} />
          ))
        : null}
    </Component>
  );
};

export default LayoutEntry;
