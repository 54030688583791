//libs
import React from 'react';
import c from 'classnames';

//components
import { Box, Stack } from '@lyearn/ui';

import Mentor from './Mentor';
//types
import { IMentor } from './Mentor';
//styles
import { container } from './styles';

const MentorsSection = ({ layoutConfig }: TSFixMe) => {
  const { title, mentors: _mentors } = layoutConfig.props;
  const mentors = _mentors as IMentor[];

  if (!mentors.length) {
    return null;
  }

  const primaryMentors = mentors.filter((mentor) => mentor.isPrimary);

  return (
    <Stack
      className={c('mx-auto w-full bg-bg-primary xs:px-56 xs:pb-64', container)}
      direction="col"
      id="mentors">
      <Box className="display-xxl xs:display-xl mb-96 text-center text-text-primary" component="h2">
        {title}
      </Box>
      {primaryMentors.map((mentor) => (
        <Mentor key={mentor.title} isPrimary mentor={mentor} />
      ))}
      <Stack className="mt-128 w-full flex-wrap pt-128">
        {mentors
          .filter((mentor) => !mentor.isPrimary)
          .map((mentor) => {
            return <Mentor key={mentor.title} mentor={mentor} />;
          })}
      </Stack>
    </Stack>
  );
};

export default MentorsSection;
