import { css } from '@emotion/css';

export const prodictGridsWrapper = css`
  max-width: 122rem;
  margin: 0 auto;
  margin: 0 auto 8rem auto;
  padding-top: 10rem;
  @media (max-width: 1670px) {
    max-width: 114rem;
  }
  @media (max-width: 1024px) {
    max-width: 94.4rem;
    margin: 0 auto 7.6rem auto;
  }
  @media (max-width: 768px) {
    max-width: 71.2rem;
    margin: 0 auto 4rem auto;
  }
  @media (max-width: 600px) {
    max-width: 49rem;
  }
  @media (max-width: 414px) {
    margin: 0 auto 4.8rem auto;
    max-width: 41.4rem;
  }
  width: 100%;
`;

export const productGrid = css`
  padding-bottom: 8rem;

  &:last-child {
    padding-bottom: 0rem;
  }
  .productCardWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8rem 6.4rem;
    @media (max-width: 1670px) {
      grid-template-columns: repeat(3, 1fr);
      gap: 8rem 3.5rem;
    }
    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 7.6rem 6rem;
    }
    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 4rem 3.2rem;
    }
    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }
`;

export const sectionTitleClasses = (labelExists: boolean) => {
  if (labelExists) {
    return 'mb-26 mt-16 xs:mt-8';
  }
  return 'mb-26 mt-40 xs:mt-16 ';
};
