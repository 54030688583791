import React from 'react';
import cn from 'classnames';

import { Box, BoxProps } from '@lyearn/ui';

const ServiceType: React.FC<BoxProps> = ({ children, className, ...rest }) => {
  return (
    <Box
      className={cn(
        'mb-12 self-start rounded-4 bg-interactive-secondary py-2 px-12 text-12 tracking-widest',
        className,
      )}
      component="div"
      {...rest}>
      <Box className="leading-20 text-interactive-primary opacity-80" component="span">
        {children}
      </Box>
    </Box>
  );
};

export default ServiceType;
