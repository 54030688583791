import { css } from '@emotion/css';

const MAX_WIDTH = '144rem';

export const container = css`
  max-width: ${MAX_WIDTH};
  padding-top: 6.5rem;
  padding-inline-start: 12rem;
  padding-inline-end: 12rem;

  @media all and (max-width: 768px) {
    padding-inline-start: 2rem;
    padding-inline-end: 2rem;
  }
`;

export const grid = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8rem 6.4rem;

  @media (max-width: 767px) {
    display: flex;
  }
`;

export const titleStyle = css`
  margin-bottom: 8.6rem;
`;
