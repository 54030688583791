import React from 'react';

import LayoutEntry from './components/LayoutEntry';
import restructureAndPopulateConfig from './utils/restructureAndPopulateConfig';
import { LayoutConfig, LayoutConfigStructured } from './types';

interface LayoutPageProps {
  layoutConfig: LayoutConfig;
}

const renderLayout = (layoutConfig: LayoutConfigStructured) => {
  const layoutKeys = Object.keys(layoutConfig.components);

  if (layoutKeys.length) {
    return layoutKeys.map((layoutKey) => (
      <LayoutEntry key={layoutKey} layoutEntry={layoutConfig.components[layoutKey]} />
    ));
  }
};

const LayoutPage = (props: LayoutPageProps) => {
  const structuredLayoutConfig = restructureAndPopulateConfig(props.layoutConfig);

  return <div>{renderLayout(structuredLayoutConfig)}</div>;
};

export default LayoutPage;
