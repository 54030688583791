import React from 'react';
import cn from 'classnames';
import { NextPage } from 'next';

import Item, { ItemProps } from './Item';
import SectionTitle, { SectionProps } from './SectionTitle';
import { curriculum } from './styles';

interface StaticComponents {
  Item?: React.FC<ItemProps>;
  SectionTitle?: React.FC<SectionProps>;
}

const Container: NextPage<any> & StaticComponents = (props) => {
  const { className } = props;
  return (
    <div className={cn('bg-bg-secondary xs:w-auto', curriculum, className)}>{props.children}</div>
  );
};

Container.Item = Item;
Container.SectionTitle = SectionTitle;

export default Container;
