import React from 'react';
import { css } from '@emotion/css';
import { useServiceContext } from 'modules/service/context/ServiceContext';

import { Box, Typography } from '@lyearn/ui';

const container = css`
  .prose {
    max-width: 65.5rem;
  }
  .prose h2,
  .prose h3,
  .prose h4 {
    color: var(--text-primary);
  }
`;

const EventPageDescription = () => {
  const { service } = useServiceContext();
  return (
    <Box className={container}>
      <Box className="prose prose-2xl text-text-paragraph">
        <Typography as="h2" color="text-text-paragraph" variant="display-m">
          {service.descriptionTitle}
        </Typography>
        <article
          className="font-primary"
          dangerouslySetInnerHTML={{ __html: service.description }}
        />
      </Box>
    </Box>
  );
};

export default EventPageDescription;
