//libs
import { useCallback, useState } from 'react';
import c from 'classnames';
//type
import { FieldType } from 'components/Form';
import { keyBy } from 'lodash';
//utils
import isEmail from 'validator/lib/isEmail';

//hooks
import { useSnackbar } from '@lyearn/ui';

//styles
import { snackbarRoot } from '../../styles';

const AIRTABLE_API_KEY = 'keyPDyOqbZTQrhw75';

const ANTARAKT_CONTACT_US_URL_SUFFIX = 'app9chIC87GLiLxrI/Contact%20Us';

// Read only Airtable Antarakt base link: https://airtable.com/invite/l?inviteId=invjmWH4IGsZ3w1BH&inviteToken=ef344f1e9a06c3b9332f3142aafae330bd8af3e0400785bf78f4f29e04ba61ca

const initialValues = { Name: '', Email: '', Message: '' };

export const FORM_FILEDS_ARRAY = [
  {
    name: 'Name',
    required: true,
    type: FieldType.Text,
    placeholder: 'Name',
    props: { className: 'rounded-4 h-48' },
  },
  {
    name: 'Email',
    required: true,
    type: FieldType.Email,
    placeholder: 'Email Address',
    props: { className: 'rounded-4 h-48' },
  },
  {
    name: 'Message',
    required: true,
    type: FieldType.Multiline,
    placeholder: 'Your message',
    props: { className: 'rounded-4' },
  },
] as const;

const FORM_FIELDS = keyBy(FORM_FILEDS_ARRAY, 'name');

const useContactForm = () => {
  const { showSnackbar } = useSnackbar();
  const [submitting, setSubmitting] = useState(false);
  const EMPTY_VALUES = () => {
    const emptyValues: any = {};
    FORM_FILEDS_ARRAY.forEach((field) => {
      emptyValues[field.name] = '';
    });

    return emptyValues;
  };

  const emptyValues = EMPTY_VALUES();

  const onSubmit = useCallback(
    (values: object, actions: any) => {
      setSubmitting(true);
      fetch(`https://api.airtable.com/v0/${ANTARAKT_CONTACT_US_URL_SUFFIX}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${AIRTABLE_API_KEY}`,
        },
        body: JSON.stringify({ fields: { ...values, Date: new Date().toISOString() } }),
      })
        .then((response) => response.json())
        .then((data) => {
          showSnackbar({
            message: 'Thanks for reaching out',
            iconStatus: 'success',
            classes: { root: c('rounded-8 border', snackbarRoot) },
          });
          setSubmitting(false);

          actions.setValues(emptyValues, false);
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
          setSubmitting(false);
        });
    },
    [emptyValues, showSnackbar],
  );

  const validate = (values: any) => {
    const errors: TSFixMe = {};

    FORM_FILEDS_ARRAY.forEach((field) => {
      const { required, type, name } = field;
      if (!required) {
        return;
      }
      if (type === FieldType.Email && values[name] && !isEmail(values[name])) {
        errors[name] = 'Please enter correct email id';
      } else if (!values[name]) {
        errors[name] = `Required`;
      }
    });

    return errors;
  };

  return { submitting, validate, onSubmit, formFields: FORM_FIELDS, initialValues };
};

export default useContactForm;
