import React from 'react';
import cn from 'classnames';
import { LayoutComponentUnflattened } from 'components/layoutPage/types';
import { useUser } from 'modules/context/Contexts/User';

import { Box, Button, Typography } from '@lyearn/ui';

import AuthWrapper from '../../modules/auth/components/AuthWrapper';
import { bgImage, header, headerFullScreen, subTitle } from './styles';

const HeroDefault = ({ layoutConfig }: { layoutConfig: LayoutComponentUnflattened }) => {
  const user = useUser();
  const { title, subtitle, image, size } = layoutConfig.props;
  const { id: userId } = user;

  const url = image as string;
  return (
    <Box
      className={cn(
        'relative mb-40 flex flex-col items-center justify-center py-26 xs:mb-30',
        { 'w-full': size === 'contained' },
        { [header]: size === 'contained' },
        { [headerFullScreen]: size === 'full-screen' },
      )}>
      <Box
        className={cn('absolute h-full overflow-hidden', bgImage(url), {
          'rounded-16': size === 'contained',
        })}>
        {title || subtitle ? (
          <Box className="absolute top-0 bottom-0 bg-interactive-primary-hover end-0 start-0" />
        ) : null}
      </Box>
      {title ? (
        <Typography className="display-xxxl lg:display-xxl xs:heading-xl max-w-5xl z-10 w-full text-center text-text-white">
          {title}
        </Typography>
      ) : null}
      {subtitle ? (
        <Typography
          className={cn(
            'heading-s lg:heading-xs z-10 mt-16 text-center text-text-white xs:mt-6',
            subTitle,
          )}>
          {subtitle}
        </Typography>
      ) : null}
      {!userId && (
        <AuthWrapper>
          <Button className="mt-26 hidden xs:block" variant="filled">
            Get Started
          </Button>
        </AuthWrapper>
      )}
    </Box>
  );
};

export { HeroDefault };
export default HeroDefault;
