import React from 'react';
import Service from 'modules/service/components';
import Link from 'next/link';
import { Service as ServiceType } from 'types/interfaces/SiteConfig';

import { ArrowForward } from '@lyearn/icons';
import { Box, Stack } from '@lyearn/ui';

const ServiceCard = ({ service }: { service: ServiceType }) => {
  return (
    <Link className="flex flex-1" href={`/service/${service.page}`}>
      <Stack className="cursor-pointer rounded-8 p-24 hover:bg-bg-secondary" direction="col">
        <Service.Media service={service} />
        <Service.Type className="mt-48">{service.type}</Service.Type>
        <Service.Name>{service.title}</Service.Name>
        <Service.Description>{service.cardDescription}</Service.Description>
        <Box className="flex-1" />
        <Service.Cta
          as="link"
          className="mt-40"
          endIcon={<ArrowForward className="rtl:rotate-180" />}>
          Learn more
        </Service.Cta>
      </Stack>
    </Link>
  );
};

export default ServiceCard;
