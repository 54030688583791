import React from 'react';
import cn from 'classnames';

import { Box } from '@lyearn/ui';

const Card: React.FC<React.ComponentProps<typeof Box>> = (props) => {
  const { className, children, ...rest } = props;

  return (
    <Box
      className={cn(
        'border border-solid border-border-dark hover:border-interactive-primary',
        className,
      )}
      {...rest}>
      {children}
    </Box>
  );
};

export default Card;
