import React, { useMemo } from 'react';
import { css } from '@emotion/css';
import cn from 'classnames';

import { Box } from '@lyearn/ui';

const getPositionClass = ({ horizontal, vertical }: { horizontal: number; vertical: number }) => ({
  'top-right': css`
    right: ${horizontal}rem;
    top: ${vertical}rem;
  `,
  'top-left': css`
    left: ${horizontal}rem;
    top: ${vertical}rem;
  `,
  'bottom-right': css`
    right: ${horizontal}rem;
    bottom: ${vertical}rem;
  `,
  'bottom-left': css`
    left: ${horizontal}rem;
    bottom: ${vertical}rem;
  `,
});

const Positioned: React.FC<
  React.ComponentProps<typeof Box> & {
    position: keyof ReturnType<typeof getPositionClass>;
    horizontal?: number;
    vertical?: number;
  }
> = ({ children, className, position, horizontal = 2.4, vertical = 2.0, ...rest }) => {
  const positionClass = useMemo(
    () => getPositionClass({ horizontal, vertical })[position],
    [position, horizontal, vertical],
  );

  return (
    <Box className={cn('absolute z-30', positionClass, className)} {...rest}>
      {children}
    </Box>
  );
};

export default React.memo(Positioned);
