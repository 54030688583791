import React from 'react';
import { css } from '@emotion/css';
import cn from 'classnames';

import { Box, Typography } from '@lyearn/ui';

import { content } from './styles';

type ContentPageProps = {
  title: string;
  body: string;
  customBullets: boolean;
};

const ContentPage = ({ title, body, customBullets }: ContentPageProps) => {
  if (!title || !body) {
    return null;
  }

  return (
    <Box
      className={cn('mx-auto px-20 pb-50 text-text-primary', content, { bullets: customBullets })}>
      <Typography as="h1" variant="display-xxl">
        {title}
      </Typography>
      <Box
        className={cn('prose prose-2xl mx-auto w-full py-40 text-text-paragraph')}
        dangerouslySetInnerHTML={{ __html: body }}
      />
    </Box>
  );
};

export default ContentPage;
