// libs
import React from 'react';
import c from 'classnames';

import { Box, Typography } from '@lyearn/ui';

// styles
import { headingStyle } from './styles';

interface StickySectionProps {
  className?: string;
  id: string;
  name: string;
  sticky?: boolean;
  top?: number; // in rem
}

const StickySection: React.FC<React.PropsWithChildren<StickySectionProps>> = (props) => {
  const { children, className, id, name, sticky = false, top = 0 } = props;

  return (
    <Box className={c('relative mb-50 bg-bg-primary', className)} component="section" id={id}>
      <Box
        className={c(
          'z-10 grid w-full items-end bg-bg-primary',
          sticky && 'sticky',
          headingStyle(top),
        )}>
        <Typography as="h2" className="mb-40 ms-20" variant="heading-l">
          {name}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};

export default StickySection;
