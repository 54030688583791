import React from 'react';
import c from 'classnames';

import { ArrowIosBack, ArrowIosForward } from '@lyearn/icons';
import { Box, DeprecatedIconButton, Stack } from '@lyearn/ui';

import { paginationStyles } from './styles';

const NAVIGATION_CLASSES =
  'transform -translate-y-1/2 -translate-x-1/2 rtl:translate-x-1/2 absolute top-1/2 shadow-03 z-10 ';

interface RenderNavigationControlsParams {
  setNextEl: (ref: HTMLButtonElement | null) => void;
  setPrevEl: (ref: HTMLButtonElement | null) => void;
}

export const renderNavigationControls = ({
  setNextEl,
  setPrevEl,
}: RenderNavigationControlsParams) => (
  <>
    <DeprecatedIconButton
      ref={setPrevEl}
      appearance="inverse"
      className={c(NAVIGATION_CLASSES, 'start-0')}>
      <ArrowIosBack className="rtl:rotate-180" />
    </DeprecatedIconButton>
    <DeprecatedIconButton
      ref={setNextEl}
      appearance="inverse"
      className={c(NAVIGATION_CLASSES, 'start-full')}>
      <ArrowIosForward className="rtl:rotate-180" />
    </DeprecatedIconButton>
  </>
);

interface RenderPaginationControlsParams {
  setPaginationEl: (ref: HTMLDivElement | null) => void;
}

export const renderPaginationControls = ({ setPaginationEl }: RenderPaginationControlsParams) => (
  <Box ref={setPaginationEl} className={c(paginationStyles, 'self-left space-s-8')} />
);
