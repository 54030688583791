//libs
import React, { useCallback, useState } from 'react';
import cn from 'classnames';
//types
import { LayoutComponentUnflattened } from 'components/layoutPage/types';
import scrollIntoView from 'scroll-into-view-if-needed';

import { VolumeMute, VolumeOn } from '@lyearn/icons';
//components
import { Box, Button, Typography } from '@lyearn/ui';

import Video from '../Video';
//styles
import { buttonWrapper, containerStyles, heroTitle, soundBtn } from './styles';

const Hero4 = ({ layoutConfig }: { layoutConfig: LayoutComponentUnflattened }) => {
  const { title, video, scrollToElementId, poster, id } = layoutConfig.props;
  const [muted, setMuted] = useState(true);

  const scrollToEndOfHero = useCallback(() => {
    const element = document.getElementById(scrollToElementId);
    if (!element) {
      return;
    }
    scrollIntoView(element, { behavior: 'smooth', block: 'start' });
  }, [scrollToElementId]);

  return (
    <Box className="relative h-screen w-full pb-64" id={id || 'hero'}>
      <Box className={cn(containerStyles, 'absolute h-full w-full ')} />
      <Box className={cn('absolute z-10', buttonWrapper)}>
        <Button
          className={cn('rounded-4')}
          fullWidth={true}
          size="large"
          onClick={scrollToEndOfHero}>
          <Box className="font-black" component="span">
            Let's AntarAkt
          </Box>
        </Button>
      </Box>
      <Typography
        as="p"
        className={cn(
          'xs:display-xxl absolute w-full whitespace-pre-wrap px-20 text-center',
          heroTitle,
        )}
        color="text-text-primary"
        variant="display-xxxl">
        {title}
      </Typography>
      <Box
        className={cn(
          'absolute bottom-96 z-10 h-40 w-40 cursor-pointer rounded-full border border-icon-placeholder p-8 end-50 xs:end-26',
          soundBtn,
        )}
        role="button"
        onClick={() => {
          // https://webkit.org/blog/6784/new-video-policies-for-ios/
          setMuted((_muted) => !_muted);
        }}>
        {muted ? (
          <VolumeMute className="h-20 w-20 text-icon-placeholder" />
        ) : (
          <VolumeOn className="h-20 w-20 text-icon-placeholder" />
        )}
      </Box>
      <Video
        autoPlay
        loop
        playsInline
        className="h-full w-full object-cover"
        muted={muted}
        poster={poster}
        src={video}
        onMute={() => setMuted(true)}
      />
    </Box>
  );
};

export { Hero4 };
export default Hero4;
