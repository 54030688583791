import React from 'react';

import { Box } from '@lyearn/ui';

import { StateItem as StateItemProps } from '../types';

const StateItem = ({ image, label, value }: StateItemProps) => {
  return (
    <Box className="flex items-center text-text-white">
      <Box className="h-48 w-48 me-20">
        <img alt="" src={image} />
      </Box>
      <Box className="items center flex flex-col">
        <Box className="body-short-02 opacity-80" color="text-text-white">
          {label}
        </Box>
        <Box className="heading-m xs:heading-s" color="text-text-white">
          {value}
        </Box>
      </Box>
    </Box>
  );
};

export default StateItem;
