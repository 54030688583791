import React from 'react';
import Form from 'components/Form';
import useFormikSubmit from 'hooks/useFormikSubmit';

import { Box, Button, Loader } from '@lyearn/ui';

import useContactForm from './hooks/useContactForm';

const ContactForm = () => {
  const [formikSubmit, setFormikSubmit] = useFormikSubmit();
  const { submitting, onSubmit, validate, formFields, initialValues } = useContactForm();

  return (
    <>
      <Form
        enableReinitialize
        fields={formFields}
        formName="ContactForm"
        initialValues={initialValues}
        overrides={{ FieldContainer: { className: 'mb-12' } }}
        setSubmitRef={setFormikSubmit}
        validate={validate}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={onSubmit}
      />
      <Button
        className="mt-8 w-96 rounded-4"
        disabled={submitting}
        size="small"
        onClick={formikSubmit as TSFixMe}>
        <Box className="font-bold" component="span">
          {submitting ? <Loader /> : 'Send'}
        </Box>
      </Button>
    </>
  );
};

export default ContactForm;
