import React from 'react';
import { useProductContext } from 'modules/context/Contexts/Product';
import { UserProduct } from 'modules/product/types';

import ProductInfoHeader from './views/ProductInfoComponent1';
import ProductInfoHeader2 from './views/ProductInfoComponent2';

type ProductInfoGridProps = {
  product: UserProduct;
  fetching: boolean;
  showMoreInfo: boolean;
};

const ProductInfoHeaderComponent = ({
  imageSize,
  hidePrice,
}: {
  imageSize: 'large' | 'medium';
  hidePrice?: boolean;
}) => {
  const { product, fetching } = useProductContext();

  return (
    <ProductInfoHeader
      fetching={fetching}
      hidePrice={hidePrice}
      imageSize={imageSize}
      product={product}
    />
  );
};

/* For Shyam monk instance homepage product grid */
const ProductInfoGridComponent = (props: ProductInfoGridProps) => {
  return <ProductInfoHeader {...props} />;
};

/* For Antarakt instance homepage product grid */
const ProductInfoGridComponent2 = (props: ProductInfoGridProps) => {
  return <ProductInfoHeader2 {...props} />;
};

export { ProductInfoGridComponent, ProductInfoGridComponent2, ProductInfoHeaderComponent };
