import React, { FunctionComponent } from 'react';
import cn from 'classnames';
import { DefaultProps } from 'types/interfaces/DefaultProps';

import { Box, Typography } from '@lyearn/ui';

import { progressWrapper } from '../styles';

export interface ResumeProps extends DefaultProps {
  percentage: number;
}

const Progress: FunctionComponent<ResumeProps> = (props) => {
  const { percentage, className } = props;
  if (percentage === 100) {
    return null;
  }
  return (
    <Box className="mb-32 w-full">
      <Typography as="div" className="mb-6" variant="heading-xs">
        {percentage}% completed
      </Typography>
      <Box
        className={cn(
          progressWrapper(percentage),
          'relative h-6 w-full bg-interactive-secondary',
          className,
        )}>
        <Box className="progress absolute h-full bg-interactive-primary start-0 rounded-e-none" />
      </Box>
    </Box>
  );
};

export default Progress;
