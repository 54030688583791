import React from 'react';
import cn from 'classnames';
import { noop } from 'lodash';

import { Button, LinkButton } from '@lyearn/ui';
import { ButtonProps } from '@lyearn/ui';

const CTA_TEXT = 'Book a time';

const ServiceCta = ({
  serviceLink,
  as,
  className,
  children,
  ...rest
}: { serviceLink?: string; as: 'link' | 'button' } & ButtonProps) => {
  const ButtonComponent = as === 'button' ? Button : LinkButton;

  return (
    <ButtonComponent
      className={cn('cursor-pointer self-start ', className)}
      onClick={serviceLink ? () => window.open(serviceLink, '_blank') : noop}
      {...(rest as any)}>
      {children ?? CTA_TEXT}
    </ButtonComponent>
  );
};

export default ServiceCta;
