import { InstructorType } from 'types/schema';

type Instructor = {
  profileImg: string;
} & InstructorType;
const transformInstructors = (instructors: InstructorType[]) => {
  const users: Instructor[] = [];

  instructors.forEach((instructor) => {
    const user = instructor as Instructor;
    user.profileImg = user.picture!;
    users.push(user);
  });

  return users;
};

export default transformInstructors;
