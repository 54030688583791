import { css } from '@emotion/css';

export const content = css`
  max-width: 106rem !important;
  padding-top: 13rem;
  .prose {
    max-width: 100% !important;
  }
  .prose * {
    color: rgba(219, 219, 219, 0.64);
  }
  .prose strong {
    color: var(--text-primary) !important;
    opacity: 1;
  }
  &.bullets ol {
    list-style-type: none !important;
    counter-reset: item !important;
    margin: 0;
    padding: 0;
  }

  &.bullets li {
    display: table;
    counter-increment: item !important;
    margin-bottom: 0.6em;
  }

  &.bullets li:before {
    position: static;
    content: counters(item, '.') '. ' !important;
    display: table-cell;
    padding-inline-end: 0.6em;
    color: inherit !important;
  }

  &.bullets li li {
    margin: 0;
  }

  &.bullets li li:before {
    position: static;
    content: counters(item, '.') ' ' !important;
  }
`;
