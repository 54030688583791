const transformDuration = (duration: number) => {
  const hour = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const hourString =
    hour !== 0 ? `${hour}${minutes === 0 ? (hour === 1 ? 'hr' : 'hrs') : 'h'}` : '';
  const concatedString = minutes !== 0 ? `${hourString} ${minutes}m` : hourString;
  return concatedString;
};

export default transformDuration;
