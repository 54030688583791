//libs
import React from 'react';
import c from 'classnames';
import Form, { FieldType } from 'components/Form';
import { keyBy } from 'lodash';
import useInstanceConfig from 'modules/siteConfig/hooks/useInstanceConfig';

import { Box, Stack, Typography } from '@lyearn/ui';

import SocialLink from '../Footer/SocialLink';
import ContactForm from './ContactForm';
import { container, formContainer, messageContainer, spacer } from './styles';

const ContactSection = ({
  title,
  heading,
  description,
  address,
  socialMediaLinks,
}: {
  title: string;
  heading: string;
  description: string;
  socialMediaLinks: any;
  address: { addressLabel: string; addressText: string };
}) => {
  const { facebook, twitter, youtube, instagram } = socialMediaLinks;
  const links = [
    { media: 'FACEBOOK', url: facebook },
    { media: 'TWITTER', url: twitter },
    { media: 'INSTAGRAM', url: instagram },
    { media: 'YOUTUBE', url: youtube },
  ];

  return (
    <Stack
      className={c('mx-auto w-full bg-bg-primary px-56 xs:flex-col xs:py-128', container)}
      id="contact"
      justifyContent="between">
      <Stack className={c('xs:w-full', messageContainer)} direction="col">
        <Typography
          className="mb-24 uppercase text-start"
          color="text-text-secondary"
          variant="label-02">
          {title}
        </Typography>
        <Typography
          as="p"
          className="xs:display-xxl mb-28 whitespace-pre-wrap text-start"
          color="text-text-primary"
          variant="display-xxxl">
          {heading}
        </Typography>
        <Box
          className=""
          dangerouslySetInnerHTML={{
            __html: `${description}`,
          }}
        />
        <Stack className="mt-24">
          <SocialLink
            colourClasses="text-interactive-primary hover:text-interactive-primary-hover"
            height={26}
            socialMediaLinks={links}
            width={26}
          />
        </Stack>
        <Box className={c('flex-1', spacer)} />
        <Box>
          <Typography className="text-interactive-primary" variant="body-short-02">
            <strong>{address.addressLabel}</strong>
            <Typography as="span" color="text-text-secondary" variant="body-short-02">
              {address.addressText}
            </Typography>
          </Typography>
        </Box>
      </Stack>
      <Stack className={c('w-1/2 xs:mx-auto xs:mt-96 xs:w-full', formContainer)} direction="col">
        <ContactForm />
      </Stack>
    </Stack>
  );
};

export default ContactSection;
