import { css } from '@emotion/css';

export const logoContainer = ({ height = '2.7rem' } = {}) => css`
  height: ${height};
  @media (max-width: 768px) {
    height: 2.3rem;
  }
`;

export const divider = css`
  height: 1px;
  /* TODO add this colour to theme */
  background-color: #eaeaea;
`;

export const linkedInIcon = css`
  background: currentColor;
`;

export const linkStyle = css`
  color: #cccccc;
`;
