import React from 'react';
import cn from 'classnames';
import ProductCard from 'components/ProductCard';
// import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';
import { useProductsContext } from 'modules/context/Contexts/Products';
import { UserProduct } from 'modules/product/types';
import { PageComponent } from 'types/interfaces/SiteConfig';

import { Box, mergeOverrides, Typography } from '@lyearn/ui';

import { prodictGridsWrapper, productGrid } from './styles';

const sectionTitleDefaultProps = {
  align: 'text-center',
  titleTypographyVariants: {
    desktop: 'heading-xl',
    mobile: 'xs:heading-l',
  },
  className: 'mb-26 mt-16 xs:mt-8 ',
};

const ProductGrid = ({ layoutConfig }: { layoutConfig: PageComponent }) => {
  const { sectionTitleProps, sectionLabel } = layoutConfig.props;
  const { products, fetching } = useProductsContext();

  const { align } = sectionTitleProps;
  const mergedSectionTitleProps = mergeOverrides(sectionTitleDefaultProps, sectionTitleProps);
  const { titleTypographyVariants } = mergedSectionTitleProps;

  const productGroups = groupBy(products, 'generalSettings.category[0]');

  return (
    <Box className={cn('px-20', prodictGridsWrapper)}>
      {products && products.length !== 0 && sectionLabel ? (
        <Typography color="text-text-placeholder" variant="label-02">
          {sectionLabel}
        </Typography>
      ) : null}
      {Object.keys(productGroups).map((key) => {
        if (productGroups[key].length < 1) {
          return null;
        }
        return (
          <Box key={key} className={productGrid}>
            <Typography
              as="h2"
              className={`${mergedSectionTitleProps.className} ${titleTypographyVariants.desktop} ${titleTypographyVariants.mobile} ${align}`}>
              {key}
            </Typography>
            <Box className={cn('productCardWrapper justify-center')}>
              {productGroups[key].map((product: UserProduct) => {
                return <ProductCard key={product.id} product={product} />;
              })}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default ProductGrid;
