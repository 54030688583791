import { css } from '@emotion/css';

export const marginStyle = css`
  margin: 0 !important;
`;

export const contentStyle = css`
  a {
    color: var(--ghost);
  }
`;
