import React from 'react';
import cn from 'classnames';

import { Box, Stack, Typography } from '@lyearn/ui';

import { pictureContainer, testimoyText } from './styles';

const TestimonialItem: React.FC<{
  text: string;
  studentName: string;
  role: string;
  picture: string;
}> = ({ text, studentName, role, picture }) => {
  return (
    <Stack className="w-full flex-shrink-0 items-center xs:flex-col xs:items-start" direction="row">
      <Stack className="order-1 xs:order-2" direction="col">
        <Box
          className={cn('prose prose-xl mb-20 flex-1 text-18 text-text-paragraph', testimoyText)}>
          {text}
        </Box>
        <Box className="flex-1" />
        <Typography color="text-text-primary" variant="heading-xs">
          {studentName}
        </Typography>
        <Typography color="text-text-primary" variant="body-long-02">
          {role}
        </Typography>
      </Stack>
      <Box
        className={cn(
          'relative order-2 items-center self-center overflow-hidden rounded-full xs:order-1 xs:self-start',
          pictureContainer,
        )}>
        <img alt="studentName" className="absolute" height="280" src={picture} width="280" />
      </Box>
    </Stack>
  );
};

export default TestimonialItem;
