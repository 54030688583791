import React from 'react';
import cn from 'classnames';
import transformDuration from 'helpers/transformDuration';
import transformValidity from 'helpers/transformValidity';
import CTAProduct from 'modules/product/components/CTA';
import { GenericProduct } from 'modules/product/types';
import { GeneralSettingsType } from 'types/schema';

import { Box, Skeleton, Typography } from '@lyearn/ui';

import ProductDetails from '../ProductDetails';
import ProductProgress from '../ProductProgress';
import ProductTitle from '../ProductTitle';
import ProductTypeLabel from '../ProductTypeLabel';
import { description } from '../styles';

type Props = {
  product: GenericProduct;
  fetching: boolean;
  showMoreInfo?: boolean;
  hidePrice?: boolean;
};

const ProductInfo1 = ({ product, fetching, showMoreInfo, hidePrice }: Props) => {
  const { generalSettings, userPerformance, purchased, pricingParameters, derivedFields } = product;
  const { progress } = userPerformance || {};
  const { certificateOnCompletion = false } = (generalSettings as GeneralSettingsType) || {};

  const details = [];

  if (derivedFields.purchaseInfo.price && pricingParameters?.validity) {
    const validityString = transformValidity(pricingParameters?.validity);
    validityString && details.push(`Validity: ${validityString}`);
  }
  if (product.contentDuration) {
    details.splice(0, 0, `Duration: ${transformDuration(product.contentDuration)}`);
  }
  return (
    <>
      <ProductTypeLabel
        certificateOnCompletion={certificateOnCompletion}
        productType={product.type}
      />
      <ProductTitle className="mb-10 xs:mt-0" title={product?.name || ''} />
      {fetching ? (
        <Skeleton animation="wave" height={25} variant="rectangular" width={150} />
      ) : purchased && progress ? (
        <ProductProgress progress={progress} />
      ) : (
        <ProductDetails className="mb-10" details={details} />
      )}
      <Typography as="div" className={cn('body-long-01 mb-10 text-text-secondary', description)}>
        {product.description}
      </Typography>

      <Box className="flex-1" />
      <CTAProduct
        fetching={fetching}
        hidePrice={hidePrice}
        product={product}
        showMoreInfo={showMoreInfo}
      />
    </>
  );
};

export default ProductInfo1;
