import React from 'react';
import cn from 'classnames';

import { Quote } from '@lyearn/icons';
import { Avatar, Box, Stack, Typography } from '@lyearn/ui';

import { itemClass, quoteStyle } from '../styles';
import { Testimonial } from '../types';

const TestimonialItem = ({ quote, user }: Testimonial) => {
  return (
    <Box
      className={cn(
        'flex w-full flex-1 flex-col rounded-16 border border-border bg-bg-secondary px-32 py-40',
        itemClass,
      )}>
      <Quote className="mb-4 h-40 w-40 fill-current opacity-25" />
      <Box className={cn('lh-22 mb-50 flex-1 text-14 italic opacity-80', quoteStyle)}>{quote}</Box>
      <Stack alignItems="center" className="text-16" direction="row">
        {user.image ? (
          <Avatar className="me-16" name={user.name || ''} size="x-large" src={user.image} />
        ) : null}
        <Stack direction="col">
          <Typography color="text-text-primary" variant="heading-xs">
            {user.name},
          </Typography>
          <Typography color="text-text-primary" variant="body-short-01">
            {user.location}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default TestimonialItem;
