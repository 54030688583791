// libs
import React, { useCallback, useState } from 'react';

import { Box } from '@lyearn/ui';

// components
import Accordion from './Accordion';
// types
import { AccordionGroupProps } from './types';

const AccordionGroup = ({ data, className = '' }: AccordionGroupProps) => {
  const [expanded, setExpanded] = useState('');

  const handleChange = useCallback(
    (id: string) => {
      if (id === expanded) {
        setExpanded('');

        return;
      }
      setExpanded(id);
    },
    [expanded],
  );

  return (
    <Box className={className}>
      {data.map((accordionData) => {
        return (
          <Accordion
            key={accordionData.id}
            expanded={expanded === accordionData.id}
            onChange={handleChange}
            {...accordionData}
          />
        );
      })}
    </Box>
  );
};

export default AccordionGroup;
