import { css } from '@emotion/css';

export const container = css`
  max-width: 116rem;
  padding: 8rem 2rem;
`;

export const titleStyle = css``;

export const imageContainer = css`
  max-height: 51.4rem;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const imageContainerXS = css`
  width: 80%;
  max-width: 35rem;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const statWrapper = css`
  margin-inline-end: 115px;
  @media (max-width: 768px) {
    margin-inline-end: 50px;
  }
  &:last-child {
    margin-inline-end: 0px;
  }
`;
