import React from 'react';
import cn from 'classnames';

import { CircleCheckMarkOutline } from '@lyearn/icons';
import { Box, Typography } from '@lyearn/ui';

import { completeIconStyle } from '../styles';

const CompletedLabel = () => {
  return (
    <Box className="flex items-center space-s-8">
      <CircleCheckMarkOutline className={cn(completeIconStyle, 'w-24 text-text-success')} />
      <Typography as="div" className="text-text-success" variant="heading-xs">
        Completed
      </Typography>
    </Box>
  );
};

export default CompletedLabel;
