import { css } from '@emotion/css';

export const container = css`
  padding: 7.2rem 0rem;

  @media all and (max-width: 768px) {
    padding: 7.2rem 2rem;
  }
`;

export const centerPadding = css`
  width: 7.2rem;
`;
