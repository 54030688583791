import React from 'react';
import { css } from '@emotion/css';
import cn from 'classnames';

import { Box, BoxProps } from '@lyearn/ui';

const fs32 = css`
  font-size: 3.2rem;
`;

const ServiceName: React.FC<BoxProps> = ({ children }) => {
  return (
    <Box className={cn('mb-20 font-secondary text-text-primary', fs32)} component="h2">
      {children}
    </Box>
  );
};

export default ServiceName;
