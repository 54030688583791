import { css } from '@emotion/css';

export const container = css`
  background-image: url('https://d7jx2atmwmj9r.cloudfront.net/uploads/images/unolona-hero-bg.png');
  background-size: cover;
  padding: 6.3rem 2rem;
`;

export const innerContainer = css`
  max-width: 112rem;
`;

export const leftCol = css`
  width: 42.32%;
  margin-inline-end: 15.48%;
  @media all and (max-width: 768px) {
    width: 100%;
    margin-inline-end: 0;
  }
`;
export const rightCol = css`
  width: 39.65%;
  @media all and (max-width: 768px) {
    width: 100%;
  }
`;

export const spacer = css`
  max-height: 6.2rem;
`;
