import React from 'react';
import { css } from '@emotion/css';
import {
  CourseContent_ContentType_Fragment,
  CourseContent_CourseContentType_Fragment,
} from 'data-op/fragments/generated/content';
import { useProductContext } from 'modules/context/Contexts/Product';

import { Container, Typography } from '@lyearn/ui';

const descriptionStyles = css`
  & .prose ul > li::marker {
    color: var(--text-secondary);
  }
`;

const ProductDescription = ({ layoutConfig }: TSFixMe) => {
  const { product } = useProductContext();

  const title = layoutConfig.props.title || 'About this course';

  const description = (
    product.content as CourseContent_ContentType_Fragment | CourseContent_CourseContentType_Fragment
  ).description;

  return (
    <Container className={descriptionStyles}>
      <Typography as="h2" className="heading-xl lg:heading-l mb-30 text-text-primary xs:mt-16">
        {title}
      </Typography>
      {description && (
        <article dangerouslySetInnerHTML={{ __html: description }} className="prose prose-2xl" />
      )}
    </Container>
  );
};

export default ProductDescription;
