import React from 'react';
import cn from 'classnames';
import { CourseContent_ContentType_Fragment } from 'data-op/fragments/generated/content';
import { GenericProduct, UserProduct } from 'modules/product/types';
import { ProductTypeEnum } from 'types/schema';

import { Box } from '@lyearn/ui';

import ProductInfo1 from '../components/ProductInfo1';
import MediaWithLabel from '../MediaWithLabel';
import { imageWrapperStyle, productInfoHeaderWrapper } from '../styles';

type ProductInfoHeaderProps = {
  product: GenericProduct;
  fetching: boolean;
  imageSize?: TSFixMe;
  showMoreInfo?: boolean;
  hidePrice?: boolean;
};

/* for All instance product page and Shyam monk instance Homepage Instance */
const ProductInfoHeader: React.FC<ProductInfoHeaderProps> = (props) => {
  const { product, imageSize = 'medium' } = props;
  const {
    type,
    media,
    derivedFields: {
      purchaseInfo: { finalPrice },
    },
    content,
  } = product;

  const _content = content as CourseContent_ContentType_Fragment;
  const mediaData = media.length > 0 ? media : _content.media;
  const { url: mediaUrl, type: mediaType } = mediaData?.[0] || {};

  return (
    <Box
      className={cn(productInfoHeaderWrapper, 'flex flex-row xs:mt-6 xs:flex-wrap', {
        'mt-50': type === ProductTypeEnum.Course,
      })}>
      <Box className={cn(imageWrapperStyle(imageSize), 'me-64 xs:w-full xs:me-0')}>
        {media ? (
          <MediaWithLabel
            className="productImage"
            imageSize={imageSize}
            media={mediaData || []}
            mediaType={mediaType!}
            mediaUrl={mediaUrl!}
          />
        ) : null}
      </Box>
      <Box
        className={cn('flex w-1/2 flex-col xs:mt-12 xs:w-full', {
          'justify-center': !finalPrice,
        })}>
        <ProductInfo1 {...props} />
      </Box>
    </Box>
  );
};

export default ProductInfoHeader;
