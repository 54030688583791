import { css } from '@emotion/css';

export const container = css`
  max-width: 112rem;
  padding: 80px 0px;
`;

export const titleStyle = css`
  color: #1b1006;
`;

export const itemClass = css`
  color: rgba(27, 16, 6, 0.3);
  max-width: 35rem;
`;

export const quoteStyle = css`
  color: rgba(27, 16, 6, 0.8);
`;
