const transformValidity = (validity: string) => {
  const [digit, type] = validity.split('_');
  switch (type) {
    case 'd':
      return `${digit} ${parseInt(digit, 10) === 1 ? `day` : `days`}`;
    case 'w':
      return `${digit}  ${parseInt(digit, 10) === 1 ? `week` : `weeks`}`;
    case 'm':
      return `${digit}  ${parseInt(digit, 10) === 1 ? `month` : `months`}`;
    case 'y':
      return `${digit}  ${parseInt(digit, 10) === 1 ? `year` : `years`}`;
    default:
      return ``;
  }
};

export default transformValidity;
