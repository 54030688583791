import React from 'react';
import { css } from '@emotion/css';
import cn from 'classnames';

import { Box } from '@lyearn/ui';

const productWrapper = css`
  max-width: calc(112rem + 5.2rem);
  margin: 0 auto;
  width: 100%;
`;

const ProductWrapper: React.FC<React.ComponentProps<typeof Box>> = ({ children, className }) => {
  return (
    <Box className={cn(productWrapper, 'mt-50 px-26 pb-40 xs:px-20', className)}>{children}</Box>
  );
};

export default ProductWrapper;
