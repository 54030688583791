import React, { useRef } from 'react';
import cn from 'classnames';
import { LayoutComponentUnflattened } from 'components/layoutPage/types';

import { Box, Button, Typography } from '@lyearn/ui';

import { bgImage, gradient, headerFullScreen } from '../styles';
import { actionBtn, container, hero3SubTitle, labelStyle, titleStyle, wrapper } from './styles';

const Hero3 = ({ layoutConfig }: { layoutConfig: LayoutComponentUnflattened }) => {
  const heroRef = useRef<any>(null);
  const { label, title, subtitle, image, showSkipToContent, size } = layoutConfig.props;

  const url = image as string;

  const scrollToEndOfHero = () => {
    if (heroRef && heroRef.current) {
      const rect = heroRef?.current?.getBoundingClientRect?.();
      const top = rect.x + rect.height;
      window.scrollTo({
        top,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div
      ref={heroRef}
      className={cn(
        'relative mb-40 px-20 xs:mb-30',
        { [headerFullScreen]: size === 'full-screen' },
        wrapper,
      )}>
      <Box
        className={cn('absolute h-full overflow-hidden', bgImage(url), {
          'rounded-16': size === 'contained',
        })}>
        {title || subtitle ? (
          <Box className={cn('absolute top-0 bottom-0 end-0 start-0', gradient)} />
        ) : null}
      </Box>
      <Box className={cn('mx-auto flex h-full flex-col justify-center xs:text-center', container)}>
        {label ? (
          <Box
            className={cn(
              'display-xl lg:display-xxl xs:display-s font-primary max-w-5xl z-10 mb-10 w-full text-text-white',
              labelStyle,
            )}>
            {label}
          </Box>
        ) : null}
        {title ? (
          <Box
            className={cn(
              'display-xxxl xs:display-xxl max-w-5xl z-10 w-full font-secondary text-text-white',
              titleStyle,
            )}>
            {title}
          </Box>
        ) : null}
        {subtitle ? (
          <Typography className={cn('z-10 mt-26 text-text-white opacity-80', hero3SubTitle)}>
            {subtitle}
          </Typography>
        ) : null}
        {showSkipToContent ? (
          <Box className={cn('pt-50', actionBtn)}>
            <Button
              appearance="secondary"
              className="flex items-center xs:mx-auto"
              size="large"
              variant="filled"
              onClick={scrollToEndOfHero}>
              View Courses
            </Button>
          </Box>
        ) : null}
      </Box>
    </div>
  );
};

export { Hero3 };
export default Hero3;
