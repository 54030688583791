import React from 'react';
import Image from 'components/Image';
import { DeprecatedMediaType, MediaTypeEnum } from 'types/schema';

import Video from './Video';

type Props = { media: DeprecatedMediaType[] } & Omit<
  React.ComponentProps<typeof Image>,
  'url' | 'type'
>;

const Media = (props: Props) => {
  const { media, ...rest } = props;

  const mediaType = media.length ? media[0].type : null;

  if (mediaType === MediaTypeEnum.Image) {
    return (
      <Image
        lazyLoad={true}
        {...media[0]}
        className="relative z-10 w-full overflow-hidden rounded-t-16 bg-white"
        {...rest}
      />
    );
  }

  return <Video {...media[0]} className="relative z-10 w-full bg-white" />;
};

export default Media;
