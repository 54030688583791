import React from 'react';
import cn from 'classnames';

import { Badge } from '@lyearn/icons';
import { Box } from '@lyearn/ui';

const CertificateTag = () => {
  return (
    <Box
      className={cn(
        'opacity-4 flex items-center rounded-full bg-interactive-blur p-6 text-icon-white',
      )}>
      <Badge className="text-icon-4 fill-current" fill="currentColor" height={20} width={20} />
    </Box>
  );
};

export default CertificateTag;
