import React, { useRef } from 'react';
import cn from 'classnames';
import { LayoutComponentUnflattened } from 'components/layoutPage/types';
import { useUser } from 'modules/context/Contexts/User';

import { ArrowCircleDown } from '@lyearn/icons';
import { Box, Button, LinkButton, Typography } from '@lyearn/ui';

import AuthWrapper from '../../modules/auth/components/AuthWrapper';
import { headerHero2, rightImageContainer, subTitleHero2 } from './hero2.styles';
import { headerFullScreen } from './styles';

const Hero2 = ({ layoutConfig }: { layoutConfig: LayoutComponentUnflattened }) => {
  const user = useUser();
  const heroRef = useRef<HTMLDivElement | null>(null);
  const { title, subtitle, image, showSkipToContent, size } = layoutConfig.props;
  const { id: userId } = user;

  const scrollToEndOfHero = () => {
    if (heroRef && heroRef.current) {
      const rect = heroRef?.current?.getBoundingClientRect?.();
      const top = rect.x + rect.height - 60;
      window.scrollTo({
        top,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div
      ref={heroRef}
      className={cn(
        'relative mb-40 flex flex-row items-center justify-between py-50 xs:mb-30 xs:flex-col xs:items-center',
        { 'w-full': size === 'contained' },
        { [headerHero2]: size === 'contained' },
        { [headerFullScreen]: size === 'full-screen' },
      )}>
      <Box className="flex-shrink-0 me-16 xs:mb-38 xs:text-center">
        {title ? (
          <Typography className="display-xxl lg:display-xxl xs:heading-xl w-full text-text-primary">
            {title}
          </Typography>
        ) : null}
        {subtitle ? (
          <Typography
            className={cn('z-10 mt-8 mb-14 text-text-primary xs:mt-6', subTitleHero2)}
            variant="body-long-01">
            {subtitle}
          </Typography>
        ) : null}
        {showSkipToContent ? (
          <LinkButton
            className="flex items-center py-2 px-4 xs:mx-auto"
            color="external"
            endIcon={<ArrowCircleDown className="h-20 w-20" />}
            onClick={scrollToEndOfHero}>
            View products
          </LinkButton>
        ) : null}
      </Box>
      {image ? (
        <Box className={rightImageContainer}>
          <img alt="" src={image} />
        </Box>
      ) : null}
      {!userId && false && (
        <AuthWrapper>
          <Button className="mt-26 hidden xs:block" variant="filled">
            Get Started
          </Button>
        </AuthWrapper>
      )}
    </div>
  );
};

export { Hero2 };
export default Hero2;
