import React from 'react';
import { PurchaseInfo } from 'modules/product/types';
import CurrencySymbol from 'types/enums/CurrencySymbol';

import { Box, Typography } from '@lyearn/ui';

const ProductPrice: React.FC<{
  price: PurchaseInfo['price'];
  currency: PurchaseInfo['currency'];
}> = ({ price, currency }) => {
  return (
    <Typography className="opacity-60" color="text-text-white" variant="body-short-01">
      <Box component="span">
        {CurrencySymbol[currency.toLowerCase() as keyof typeof CurrencySymbol]}{' '}
      </Box>
      {price}
    </Typography>
  );
};

export default ProductPrice;
