import React from 'react';
import { differenceInDays, format, formatDistanceStrict, isBefore, isValid } from 'date-fns';

import { Typography } from '@lyearn/ui';

const DiscountMessage: React.FC<{
  discountDuration: string;
  discountAmount: number;
  price: number;
}> = ({ discountDuration, discountAmount, price }) => {
  const today = new Date();
  const discountEndDate = new Date(discountDuration);

  const isValidDate = discountEndDate && isValid(discountEndDate);
  const isExpired = isBefore(discountEndDate, today);
  const showDate = differenceInDays(discountEndDate, today) > 1;

  if (isValidDate && !isExpired && price > 0 && discountAmount > 0) {
    return (
      <Typography className="pt-14" color="text-text-secondary" variant="body-short-02">
        Offer available
        {showDate
          ? ` till ${format(discountEndDate, 'MMMM dd, yyyy')}!`
          : ` for ${formatDistanceStrict(discountEndDate, today)}!`}
      </Typography>
    );
  }

  return null;
};

export default DiscountMessage;
