import React from 'react';
import cn from 'classnames';
import { playButtonContainer, playButtonStyles } from 'components/AboutSection/styles';

import useToggle from '@lyearn/core/hooks/useToggle';
import { Close, Play } from '@lyearn/icons';
import { Box, DeprecatedIconButton, Dialog, Stack } from '@lyearn/ui';

type Props = {
  videoUrl: string;
  poster: string;
};

const VideoWithPopup = (props: Props) => {
  const { videoUrl, poster } = props;
  const [open, { toggle }] = useToggle(false);

  return (
    <Stack>
      <Box className={cn('relative cursor-pointer')} role="button" onClick={toggle}>
        <DeprecatedIconButton
          className={cn(
            playButtonContainer,
            'playBtn transfrom absolute inset-2/4 z-10 h-64 w-64 -translate-y-1/2 -translate-x-1/2 bg-bg-primary rtl:translate-x-1/2',
          )}>
          <Play className={cn('ms-4', playButtonStyles)} />
        </DeprecatedIconButton>
        <img alt="" className="rounded-4" src={poster} />
      </Box>
      <Dialog
        classes={{ paper: 'max-w-full relative h-screen w-10/12 bg-bg-primary' }}
        open={open}
        onClose={toggle}>
        <Close
          className="absolute top-0 z-20 mt-24 h-40 w-40 cursor-pointer text-white me-24 end-0"
          role="button"
          onClick={toggle}
        />
        <video
          autoPlay
          controls
          className="h-full w-full rounded-4"
          poster={poster}
          src={videoUrl}
        />
      </Dialog>
    </Stack>
  );
};

export default VideoWithPopup;
