import React, { FC } from 'react';
import cn from 'classnames';
import { DefaultProps } from 'types/interfaces/DefaultProps';

import { PlayOutlineLegacy } from '@lyearn/icons';
import { LinkButton, Typography } from '@lyearn/ui';

const Resume: FC<DefaultProps> = (props) => {
  const { className } = props;
  return (
    <LinkButton
      className={cn('py-2 px-4', 'flex items-center', className)}
      color="external"
      onClick={(event) => event}>
      <PlayOutlineLegacy className="h-20 w-20 me-6" />
      <Typography variant="heading-xs">Resume</Typography>
    </LinkButton>
  );
};

export default Resume;
