// libs
import React, { memo } from 'react';
import c from 'classnames';
// components
import AccordionGroup from 'components/Accordion/AccordionGroup';
import useInstanceConfig from 'modules/siteConfig/hooks/useInstanceConfig';

import { Box, LinkButton, Typography } from '@lyearn/ui';

// styles
import { wrapperStyle } from './styles';

function getFAQData(faq: any[], homepageData: { faq: any }) {
  return faq
    .filter((item) => item.type === homepageData.faq.typeToShow)
    .map((item) => ({
      id: item.id,
      summary: item.question,
      details: item.answer,
    }));
}

interface FAQSectionProps {
  layoutConfig: { props: { name: string; helperText: string } };
}

const FAQSection = ({ layoutConfig }: FAQSectionProps) => {
  const { name, helperText } = layoutConfig.props;
  const { site } = useInstanceConfig();
  const data = getFAQData(site.faq, site.homepageData);

  return (
    <Box className={c(wrapperStyle, 'mx-auto mb-160')}>
      <Typography className="heading-xl xs:heading-l mb-44 text-center">
        {name || 'Frequently Asked Questions'}
      </Typography>
      <AccordionGroup className="mb-16" data={data} />
      {helperText ? (
        <Box className="wrap flex items-center justify-center space-s-4">
          <Typography as="div" className="body-short-02 text-center" color="text-text-secondary">
            {helperText}
            {/* @ts-ignore TODO: fix type in @lyearn/ui */}
            <LinkButton
              className="py-2 px-4 pt-0 pb-4"
              color="external"
              href="/faq"
              target="_blank">
              Know More
            </LinkButton>
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default memo(FAQSection);
