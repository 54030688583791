import React from 'react';
import cn from 'classnames';

import { Box } from '@lyearn/ui';

const Container = ({ layoutConfig, children }: { layoutConfig: any; children: any[] }) => {
  const { props } = layoutConfig;
  const { className = '' } = props;
  return <Box className={cn(className)}>{children}</Box>;
};

export default Container;
