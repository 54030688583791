import ServiceCta from './Cta';
import ServiceDescription from './Description';
import ServiceDuration from './Duration';
import ServiceMedia from './Media';
import ServiceName from './Name';
import ServicePrice from './Price';
import ServiceType from './Type';

const Service = {
  Name: ServiceName,
  Type: ServiceType,
  Media: ServiceMedia,
  Description: ServiceDescription,
  Cta: ServiceCta,
  Duration: ServiceDuration,
  Price: ServicePrice,
};

export default Service;
