import React from 'react';
import cn from 'classnames';

import { Box } from '@lyearn/ui';

import StateItem from './components/StateItem';
import { container, imageContainer, imageContainerXS, statWrapper, titleStyle } from './styles';
import { AboutMeProps } from './types';

const AboutMe = (props: AboutMeProps) => {
  const { title, description, image, imageAlt, stats, style } = props;
  return (
    <Box style={style}>
      <Box className={cn('mx-auto flex', container)}>
        <Box className="w-1/2 sm:w-full xs:w-full">
          <h2 className={cn('mb-24 text-28 font-bold text-text-white', titleStyle)}>{title}</h2>
          {image ? (
            <Box
              className={cn(
                'mx-auto mb-30 flex hidden items-center justify-center sm:block xs:block',
                imageContainerXS,
              )}>
              <img alt={imageAlt} src={image} />
            </Box>
          ) : null}
          <h3
            dangerouslySetInnerHTML={{ __html: description }}
            className={cn('body-long-01 mb-50 text-text-white')}
          />
          <Box className="flex flex-row">
            {stats.map((stat, index) => (
              <Box key={index} className={statWrapper}>
                <StateItem {...stat} />
              </Box>
            ))}
          </Box>
        </Box>
        {image ? (
          <Box
            className={cn('flex w-1/2 items-end justify-end sm:hidden xs:hidden', imageContainer)}>
            <img alt={imageAlt} src={image} />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default AboutMe;
