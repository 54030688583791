import AboutMe from 'components/AboutMe';
import AboutSection from 'components/AboutSection';
import AntaraktTestimonials from 'components/Antarakt/Testimonials';
import Authors from 'components/Authors';
import ContactSection from 'components/ContactSection';
import Container from 'components/Container';
import Content from 'components/Content';
import Curriculum from 'components/Curriculum';
import Divider from 'components/Divider';
import FAQSection from 'components/FAQSection';
import FAQView from 'components/FAQView';
import FlexCol from 'components/FlexCol';
import FlexRow from 'components/FlexRow';
import Footer from 'components/Footer';
import Footer2 from 'components/Footer/Footer2';
import Header from 'components/Header';
import { Hero2, Hero3, Hero4, HeroDefault } from 'components/Hero';
import MentorsSection from 'components/MentorsSection';
import ProductDescription from 'components/ProductDescription';
import ProductGrid from 'components/ProductGrid';
import CollectionOfferingGrid from 'components/ProductGrid/CollectionOfferingGrid';
import ProductGridV2 from 'components/ProductGridV2';
import ProductGridV3 from 'components/ProductGridV3';
import { ProductInfoHeaderComponent } from 'components/ProductInfoHeader';
import ProductWrapper from 'components/ProductWrapper';
import Testimonials from 'components/Testimonials';
import AboutUs from 'components/Unolona/AboutUs';
import Hero5 from 'components/Unolona/Hero5';
import OurStudents from 'components/Unolona/OurStudents';
import TakeTest from 'components/Unolona/TakeTest';
import ProductServiceHeader from 'modules/service/views/ProductServiceHeader';
import ServiceBody from 'modules/service/views/ServiceBody';
import ServicePageDescription from 'modules/service/views/ServiceDescription';
import ServiceExpert from 'modules/service/views/ServiceExpert';
import ServiceGrid from 'modules/service/views/ServiceGrid';

export const COMPONENT_REF = {
  // Common layout components
  '@lyearn/components/Header': Header,
  '@lyearn/components/Footer': Footer,
  '@lyearn/components/Footer2': Footer2,
  '@lyearn/components/FlexCol': FlexCol,
  '@lyearn/components/FlexRow': FlexRow,
  '@lyearn/components/Container': Container,
  '@lyearn/components/FAQSection': FAQSection,

  // FAQ page layout components
  '@lyearn/components/FAQView': FAQView,

  // Homepage layout components
  '@lyearn/components/Hero': HeroDefault,
  '@lyearn/components/Hero2': Hero2,
  '@lyearn/components/Hero3': Hero3,
  '@lyearn/components/Hero4': Hero4,
  '@lyearn/components/Hero5': Hero5,
  '@lyearn/components/AboutSection': AboutSection,
  '@lyearn/components/ProductGrid': ProductGrid,
  '@lyearn/components/ProductGridV2': ProductGridV2,
  '@lyearn/components/ProductGridV3': ProductGridV3,
  '@lyearn/components/ServiceGrid': ServiceGrid,
  '@lyearn/components/MentorsSection': MentorsSection,
  '@lyearn/components/ContactSection': ContactSection,

  // Product page layout components
  '@lyearn/components/Divider': Divider,
  '@lyearn/components/ProductDetails': ProductWrapper,
  '@lyearn/components/ProductInfoHeader': ProductInfoHeaderComponent,
  '@lyearn/components/Curriculum': Curriculum,
  '@lyearn/components/ProductDescription': ProductDescription,
  '@lyearn/components/Authors': Authors,
  '@lyearn/components/CollectionOfferingGrid': CollectionOfferingGrid,
  '@lyearn/components/ProductServiceHeader': ProductServiceHeader,
  '@lyearn/components/ServiceDescription': ServicePageDescription,
  '@lyearn/components/ServiceExpert': ServiceExpert,
  '@lyearn/components/ServiceBody': ServiceBody,

  // Antarakt blocks
  '@lyearn/components/Antarakt/Testimonials': AntaraktTestimonials,

  // Raga-courses blocks
  '@lyearn/components/AboutMe': AboutMe,
  '@lyearn/components/Testimonials': Testimonials,

  // Unolona blocks
  '@lyearn/components/Unolona/AboutUs': AboutUs,
  '@lyearn/components/Unolona/OurStudents': OurStudents,
  '@lyearn/components/Unolona/TakeTest': TakeTest,

  '@lyearn/components/Content': Content,
};
