import { css } from '@emotion/css';

export const productInfoHeaderWrapper = css`
  width: 100%;
`;

export const verticalDivider = css`
  width: 0.1rem;
  height: undefined;
`;

export const mediaWrapper = (imageSize: 'medium' | 'large') => css`
  height: 0rem;
  width: 100%;
  border-radius: 24px; /* static value (not using token, as adviced by Pooja) */
  padding-bottom: ${imageSize === 'medium' ? '60%' : '65%'};
`;

export const borderRadius = css`
  border-radius: 24px; /* static value (not using token, as adviced by Pooja) */
`;

export const mediaContainer = css`
  position: absolute;
  width: 100%;
  height: 100%;
  & img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;

export const description = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const imageWrapperStyle = (imageSize: 'medium' | 'large') => css`
  width: ${imageSize === 'medium' ? '42.4rem' : '60%'};

  @media (max-width: 767px) {
    width: 100% !important; /* To DO - handle this case properly */
  }
  /* @media (min-width: 767px) {
    margin-inline-end: undefined;
  } */
`;

export const iconStyle = css`
  font-size: 2.4rem !important;
`;
