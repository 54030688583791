import React from 'react';
import cn from 'classnames';
import Service from 'modules/service/components';
import { useServiceContext } from 'modules/service/context/ServiceContext';

import { Box, Stack } from '@lyearn/ui';

import { centerPadding, container } from './styles';

const ProductServiceHeader = () => {
  const { service } = useServiceContext();

  return (
    <Stack className={cn(container, 'mb-30 xs:flex-col xs:px-40')}>
      <Box className="w-1/2 xs:w-full">
        <img alt={service.title} className="rounded-4" src={service.headerImage} />
      </Box>
      <Box className={centerPadding} />
      <Stack className="w-1/2 xs:w-full" direction="col">
        <Service.Type className="xs:mt-28">{service.type}</Service.Type>
        <Service.Name>{service.title}</Service.Name>
        <Service.Description>{service.shortDescription}</Service.Description>
        <Stack className="mt-28 mb-48">
          <Service.Duration className="me-20" duration={service.duration} />
          <Service.Price price={service.price} />
        </Stack>
        <Service.Cta as="button" className="rounded-4" serviceLink={service.serviceLink} />
      </Stack>
    </Stack>
  );
};

export default ProductServiceHeader;
