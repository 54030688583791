import React from 'react';
import { css } from '@emotion/css';
import cn from 'classnames';
import Image from 'components/Image';
import ProductMediaDisplay from 'types/enums/ProductMediaDisplay';
import { DefaultProps } from 'types/interfaces/DefaultProps';
import { MediaTypeEnum } from 'types/schema';

import { Box, Typography } from '@lyearn/ui';

import { User as UserType } from '../../types/interfaces';

const borderR = css`
  border-radius: 1.2rem;
`;

export interface UserProps extends DefaultProps {
  userDetails: UserType;
}

const User = (props: UserProps) => {
  const { userDetails, className } = props;
  const { name, designation, profileImg } = userDetails;
  return (
    <Box className={cn('flex items-center', className)}>
      <Image
        className={cn('h-80 w-80 pb-0 me-10', borderR)}
        displayType={ProductMediaDisplay.Square}
        type={MediaTypeEnum.Image}
        url={profileImg || ''}
      />
      <Box className="whitespace-nowrap">
        <Typography as="div" className="user-name text-text-primary" variant="heading-xs">
          {name}
        </Typography>
        {designation && (
          <Typography
            as="div"
            className="user-designation mt-6 text-text-primary"
            variant="body-long-02">
            {designation}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default User;
