// libs
import React from 'react';
import cn from 'classnames';

import { Box, Stack, Typography } from '@lyearn/ui';

// components
import SocialLinks from './SocialLink';
// styles
import { divider, logoContainer } from './styles';

const renderLink = (link: TSFixMe, index: number, arr: TSFixMe) => {
  const isLast = arr.length === index + 1;

  const { target = undefined, rel = undefined } = link.openInNewWindow
    ? { target: '_blank', rel: 'noreferrer' }
    : {};

  return (
    <a
      key={index}
      className={cn('heading-xxs text-text-placeholder hover:text-bg-accent xs:pb-10', {
        'me-48 xs:me-0': !isLast,
      })}
      href={link.url}
      rel={rel}
      target={target}>
      {link.text}
    </a>
  );
};

const Footer = (props: TSFixMe) => {
  const { homepageLink, socialMediaLinks = [], links, copyrightText, supportText, fixed } = props;
  return (
    <Box
      className={cn(
        'z-20 w-full bg-bg-secondary px-80 pt-32 pb-22 text-text-placeholder sm:px-20 xs:px-20',
        fixed && 'fixed bottom-0 xs:static',
      )}>
      <Stack alignItems="center" className="xs:flex-col" justifyContent="between">
        <Box className="xs:mb-22">
          <a
            className={cn('block', logoContainer(homepageLink.imageDimensions))}
            href={homepageLink.pageUrl}>
            <img alt="logo" className="h-full" src={homepageLink.imageUrl} />
          </a>
        </Box>

        {links.length > 0 ? (
          <Stack alignItems="center" className="px-30 xs:mb-22 xs:flex-col">
            {links.map(renderLink)}
          </Stack>
        ) : null}

        <Stack>
          <SocialLinks socialMediaLinks={socialMediaLinks} />
        </Stack>
      </Stack>
      <Box className={cn(divider, 'mt-28 mb-22 w-full')} />
      <Stack
        className="sm:flex-col sm:items-center xs:flex-col xs:items-center xs:text-center"
        justifyContent="between">
        <Box className="body-short-03 xs:body-short-02 text-text-placeholder sm:mb-8 xs:mb-8">
          {copyrightText}
        </Box>
        <Box className="body-short-03 xs:body-short-02 text-text-placeholder">{supportText}</Box>
      </Stack>
    </Box>
  );
};

export default Footer;
