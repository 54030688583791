//libs
import React, { useCallback, useEffect, useRef, useState } from 'react';

type VideoProps = {
  src: string;
  className?: string;
  playsInline?: boolean;
  loop?: boolean;
  muted?: boolean;
  autoPlay?: boolean;
  poster?: string;
  controls?: boolean;
  onMute?: () => void;
};

const Video = ({
  src,
  className,
  playsInline,
  loop,
  muted,
  autoPlay,
  poster,
  controls,
  onMute,
}: VideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [shouldLoop, setShouldLoop] = useState(false);

  const onVideoEnded = useCallback(() => {
    // after the video ends for the first time, mute it and put on loop
    if (loop) {
      onMute?.();
      videoRef?.current?.play();
      setShouldLoop(true);
    }
  }, [loop, onMute]);

  //TOD0: figure a wat to autoplay unmuted video on start
  //Problem: https://stackoverflow.com/questions/50890419/html-video-autoplay-without-muted-or-how-does-youtube-do-it

  useEffect(() => {
    if (autoPlay) {
      videoRef?.current?.play().catch((error) => {
        if (error) {
          onMute?.();

          /* wait for prop update */
          setTimeout(() => videoRef?.current?.play(), 100);
        }
      });
    }
  }, [autoPlay, onMute]);

  return (
    <video
      ref={videoRef}
      className={className}
      controls={controls}
      loop={shouldLoop}
      muted={muted}
      playsInline={playsInline}
      poster={poster}
      src={src}
      onEnded={onVideoEnded}
    />
  );
};

export default Video;
