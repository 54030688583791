import React from 'react';
import cn from 'classnames';
import { Service } from 'types/interfaces/SiteConfig';

import { Typography } from '@lyearn/ui';

const ServicePrice: React.FC<
  { price: Service['price'] } & React.ComponentProps<typeof Typography>
> = ({ price, className, ...rest }) => {
  return (
    <Typography
      as="span"
      className={cn('flex items-center', className)}
      color="text-text-secondary"
      variant="heading-xxs"
      {...rest}>
      {price.current}
      <Typography
        as="span"
        className={cn('flex items-center line-through ms-8', className)}
        color="text-text-tertiary"
        variant="heading-xxs">
        {price.full}
      </Typography>
    </Typography>
  );
};

export default ServicePrice;
