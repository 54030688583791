import React from 'react';
import cn from 'classnames';
import { DefaultProps } from 'types/interfaces/DefaultProps';

import { Typography } from '@lyearn/ui';

export interface TagProps extends DefaultProps {
  text?: string;
}

const Tag: React.FC<TagProps> = (props) => {
  const { text, className } = props;
  return (
    <Typography
      as="div"
      className={cn('rounded-4 bg-interactive-blur py-4 px-8 text-text-white shadow-01', className)}
      variant="label-01">
      {text}
    </Typography>
  );
};

export default Tag;
