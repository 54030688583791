import React from 'react';
import cn from 'classnames';

import Card from './Card';

const Collection = ({ items = 3 }) => {
  const itemsLength = Math.min(items - 1, 2);
  return (
    <>
      {Array.from(Array(Math.min(items - 1, 2)).keys()).map((index) => (
        <Card
          key={index}
          className={cn(
            'absolute h-full rounded-b-16 bg-bg-primary group-hover:border-interactive-primary',
            `z-${(itemsLength - (index + 1)) * 10}`,
          )}
          style={{
            top: `${(index + 1) * 8}px`,
            width: `calc(100% - ${(index + 1) * 12}px)`,
            left: `${(index + 1) * 6}px`,
          }}
        />
      ))}
    </>
  );
};

export default Collection;
