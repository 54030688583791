import { css } from '@emotion/css';

export const overlay = css`
  inset: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.0086472) 6.67%,
    rgba(0, 0, 0, 0.03551) 13.33%,
    rgba(0, 0, 0, 0.0816599) 20%,
    rgba(0, 0, 0, 0.147411) 26.67%,
    rgba(0, 0, 0, 0.231775) 33.33%,
    rgba(0, 0, 0, 0.331884) 40%,
    rgba(0, 0, 0, 0.442691) 46.67%,
    rgba(0, 0, 0, 0.557309) 53.33%,
    rgba(0, 0, 0, 0.668116) 60%,
    rgba(0, 0, 0, 0.768225) 66.67%,
    rgba(0, 0, 0, 0.852589) 73.33%,
    rgba(0, 0, 0, 0.91834) 80%,
    rgba(0, 0, 0, 0.96449) 86.67%,
    rgba(0, 0, 0, 0.991353) 93.33%,
    #000000 100%
  );
`;

/* token mapping left from desgin */
export const buttonLabel = css`
  color: #e7dec9;
`;

export const container = (expanded: boolean, maxHeight: string) => css`
  max-height: ${expanded ? '' : maxHeight};
`;
