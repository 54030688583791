import React from 'react';

import { Typography } from '@lyearn/ui';

const ServiceDescription: React.FC<React.ComponentProps<typeof Typography>> = ({ children }) => {
  return (
    <Typography color="text-text-paragraph" variant="body-long-01">
      {children}
    </Typography>
  );
};

export default ServiceDescription;
