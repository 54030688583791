import Price from 'components/Price';
import { noop } from 'lodash';
import AuthWrapper from 'modules/auth/components/AuthWrapper';
import CertificatePreview from 'modules/certificate/components/CertificatePreview';
import { useUser } from 'modules/context/Contexts/User';
import getProductUrls from 'modules/product/utils/getProductUrls';

import { ArrowForward } from '@lyearn/icons';
import { Button, Skeleton, Stack } from '@lyearn/ui';
import { TypographyVariants } from '@lyearn/ui';

import DiscountMessage from '../DiscountMessage';
import useCtaButtons from './hooks/useCtaButtons';
import { CTAProps } from './types';

const SkeletonRender = <Skeleton animation="wave" height={40} variant="rectangular" width={210} />;

const pricingOverrides = {
  priceProps: {
    variant: 'heading-xl' as TypographyVariants,
  },
};

const CTAProduct = ({
  product,
  fetching,
  showMoreInfo = false,
  moreInfoText,
  hidePrice,
}: CTAProps) => {
  const user = useUser();

  const {
    certificate,
    derivedFields: { purchaseInfo },
  } = product;

  const { completionDate } = certificate || {};

  const { PrimaryButton, SecondaryButton, showCertificate, toggleCertificate } = useCtaButtons({
    product,
    user,
    fetching,
    moreInfoText,
    showMoreInfo,
  });

  const hidePricing = product.isChildProduct || hidePrice || product.purchased;

  const PricingRenderer =
    !hidePricing && !fetching ? (
      <Price overrides={pricingOverrides} purchaseInfo={product.derivedFields.purchaseInfo} />
    ) : null;

  const PrimaryButtonRender = (
    <Button
      appearance="primary"
      className=""
      disabled={PrimaryButton.disabled}
      endIcon={<ArrowForward className="h-26 w-26 fill-current ms-6 rtl:rotate-180" />}
      loading={PrimaryButton.loading}
      size="large"
      variant={PrimaryButton.variant}
      onClick={user.id ? PrimaryButton.onClick : noop}>
      {PrimaryButton.text}
    </Button>
  );

  const PrimaryButtonAuthRender = user.id ? (
    PrimaryButtonRender
  ) : (
    <AuthWrapper
      redirectPath={process.browser ? getProductUrls({ product }).productCheckoutUrl : '/'}
      onClick={PrimaryButton.onClick}>
      {PrimaryButtonRender}
    </AuthWrapper>
  );

  const ButtonsRender = (
    <>
      {PrimaryButtonAuthRender}
      {SecondaryButton ? (
        <Button
          appearance="secondary"
          className="ms-20"
          disabled={SecondaryButton.disabled}
          endIcon={<ArrowForward className="h-26 w-26 fill-current ms-6 rtl:rotate-180" />}
          loading={SecondaryButton.loading}
          size="large"
          variant={SecondaryButton.variant}
          onClick={SecondaryButton.onClick}>
          {SecondaryButton.text}
        </Button>
      ) : null}
    </>
  );

  return (
    <Stack direction="col">
      {PricingRenderer}
      <Stack className="pt-16">{fetching ? SkeletonRender : ButtonsRender}</Stack>
      <DiscountMessage
        discountAmount={purchaseInfo.discountAmount || 0}
        discountDuration={purchaseInfo.discountValidity}
        price={purchaseInfo.finalPrice}
      />
      {showCertificate ? (
        <CertificatePreview
          certificateCompletionDate={completionDate}
          certificateId={product.certificate?.id}
          certificateVersion={product.certificate?.certificateVersion}
          onLayoutCloseClick={toggleCertificate}
        />
      ) : null}
    </Stack>
  );
};

export default CTAProduct;
