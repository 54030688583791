import { css } from '@emotion/css';

import { subTitle } from '../styles';

export const wrapper = css`
  height: 65vh;
  margin: 0px -10px;
  overflow: visible;
  @media (max-width: 767px) {
    height: 100vh !important;
  }
`;

export const container = css`
  max-width: 112rem;
`;

export const labelStyle = css``;

export const actionBtn = css`
  @media (max-width: 767px) {
    padding-top: 90px;
  }
`;

export const hero3SubTitle = css`
  ${subTitle}
  @media (max-width: 767px) {
    max-width: none;
    width: 100%;
    font-size: 1.6rem;
  }
`;

export const titleStyle = css`
  font-family: var(--font-secondary);
  font-weight: var(--normal);
`;
