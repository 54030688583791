import React from 'react';
import { css } from '@emotion/css';
import cn from 'classnames';
import { ProductTypeEnum } from 'types/schema';

import { Badge } from '@lyearn/icons';
import { Box, Typography } from '@lyearn/ui';
import { TypographyColors } from '@lyearn/ui';

const stripeBg = css`
  background: rgba(255, 255, 255, 0.1);
`;

const StripeBG: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Box className={cn('radius-04 px-12 py-2', stripeBg)}>{children}</Box>
);

interface Props {
  certificateOnCompletion: boolean;
  productType: ProductTypeEnum;
  className?: string;
  titleColour?: TypographyColors;
  withBGStripe?: boolean;
  prefix?: React.ReactNode;
}

const ProductTypeLabel = ({
  productType,
  certificateOnCompletion,
  className,
  titleColour,
  withBGStripe,
  prefix,
}: Props) => {
  const titleComponent = (
    <Typography
      as="div"
      className={cn('heading-xs lg:heading-xxs', className)}
      color={titleColour ?? 'text-text-placeholder'}>
      {`${prefix ? prefix : ''}${
        productType === ProductTypeEnum.Collection ? 'Collection' : 'Course'
      }`}
    </Typography>
  );

  return (
    <Box className="flex items-center xs:mt-26">
      {withBGStripe ? <StripeBG>{titleComponent}</StripeBG> : titleComponent}
      {certificateOnCompletion ? (
        <Typography className="label-01 flex items-center rounded-8 bg-s-info-light px-10 py-6 text-text-info ms-10">
          <Badge className="inline h-16 w-16 fill-current me-6" />
          CERTIFICATE
        </Typography>
      ) : null}
    </Box>
  );
};

export default ProductTypeLabel;
