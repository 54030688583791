// libs
import React from 'react';
import { css } from '@emotion/css';
import cn from 'classnames';
import { PageComponent } from 'types/interfaces/SiteConfig';

import { Box } from '@lyearn/ui';

// components
// styles
import { linkStyle, logoContainer } from './styles';

const container = css`
  border-top: solid #242424 1px;
  max-width: 144rem;
  padding-inline-start: 12rem;
  padding-inline-end: 12rem;
  @media all and (max-width: 768px) {
    padding-inline-start: 2rem;
    padding-inline-end: 2rem;
  }
`;

const renderLink = (link: TSFixMe, index: number, arr: TSFixMe) => {
  const isLast = arr.length === index + 1;
  const { target = undefined, rel = undefined } = link.openInNewWindow
    ? { target: '_blank', rel: 'noreferrer' }
    : {};

  return (
    <a
      key={index}
      className={cn('heading-xxxs opacity-60 hover:opacity-100 sm:pb-10 xs:pb-10', linkStyle, {
        'me-48 sm:me-0 xs:me-0': !isLast,
      })}
      href={link.url}
      rel={rel}
      target={target}>
      {link.text}
    </a>
  );
};

const Footer2 = (props: PageComponent['props']) => {
  const { homepageLink, links, fixed } = props;
  return (
    <Box
      className={cn(
        'z-20 mx-auto w-full px-80 py-40 text-text-placeholder sm:py-40 sm:px-20 sm:px-20 xs:py-40 xs:px-20 xs:px-20',
        fixed && 'fixed bottom-0 sm:static xs:static',
        container,
      )}>
      <Box className="flex flex-row items-center justify-between sm:flex-col xs:flex-col">
        <Box className="sm:mb-32 xs:mb-32">
          <a
            className={cn('block', logoContainer(homepageLink.imageDimensions))}
            href={homepageLink.pageUrl}>
            <img alt="logo" className="h-full" src={homepageLink.imageUrl} />
          </a>
        </Box>
        {links.length > 0 ? (
          <Box className="flex flex-row items-center text-16 ps-30 sm:mb-0 sm:flex-col sm:ps-0 xs:mb-0 xs:flex-col xs:ps-0">
            {links.map(renderLink)}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default Footer2;
