import React from 'react';
import cn from 'classnames';
import ServiceCard from 'modules/service/views/ServiceCard';
import useInstanceConfig from 'modules/siteConfig/hooks/useInstanceConfig';
import { Service } from 'types/interfaces/SiteConfig';

import { Box } from '@lyearn/ui';

import { container, grid, titleStyle } from './styles';

const ServiceGrid = ({ title }: { title: React.ReactNode }) => {
  const { site } = useInstanceConfig();
  const { services = [] } = site;

  if (services.length < 1) {
    return null;
  }

  return (
    <Box className={cn('mx-auto', container)} id="services">
      <Box
        className={cn(
          'display-xxl xs:display-xl mt-40 text-center text-text-primary xs:mt-16',
          titleStyle,
        )}
        component="h2">
        {title}
      </Box>
      <Box className={cn('mx-auto xs:flex-col', grid)}>
        {services.map((event: Service) => (
          <ServiceCard key={event.page} service={event} />
        ))}
      </Box>
    </Box>
  );
};

export default ServiceGrid;
