//libs
import React from 'react';
import c from 'classnames';

//components
import { Box, Stack, Typography } from '@lyearn/ui';

//styles
import {
  coMentorName,
  coMentorTitle,
  detialsContianter,
  emDashStyles,
  mainMentorName,
  mainMentorTitle,
  mentorImage,
} from './styles';

export interface IMentor {
  image: string;
  name: string;
  title: string;
  isPrimary?: boolean;
  description?: string;
}

const PRIMARY_STYLES = {
  root: 'mx-auto mt-24 mb-128 relative w-2/5 xs:w-full',
  text: {
    container: c('absolute bottom-10 -mb-96 mx-auto xs:-mb-160', detialsContianter),
    name: mainMentorName,
    title: mainMentorTitle,
    description: 'ps-96 mt-28 w-400 xs:ps-20',
    emDash: c('mt-12 ms-10 w-24 h-1', emDashStyles),
  },
  image: 'mx-auto',
};

const SECONDARY_STYLES = {
  root: 'mx-auto relative whitespace-pre-wrap xs:mb-56',
  image: `pb-24 ${mentorImage}`,
  text: {
    container: c('absolute bottom-40 ps-40 xs:ps-0 w-full', detialsContianter),
    name: coMentorName,
    title: c(coMentorTitle, 'mt-8'),
    emDash: c('mt-6 ms-4 w-24 h-1', emDashStyles),
    description: '',
  },
};

const Mentor = ({ isPrimary = false, mentor }: { isPrimary?: boolean; mentor: IMentor }) => {
  const styles = isPrimary ? PRIMARY_STYLES : SECONDARY_STYLES;
  return (
    <Box className={styles.root}>
      <Box className={styles.text.container}>
        <Stack alignItems="center">
          <Typography className={styles.text.name} color="text-text-primary">
            {mentor.name}
          </Typography>
          <Box className={styles.text.emDash} />
        </Stack>
        <Typography className={styles.text.title}>{mentor.title}</Typography>
        {mentor.description && (
          <Typography
            className={styles.text.description}
            color="text-text-placeholder"
            variant="body-long-01">
            {mentor.description}
          </Typography>
        )}
      </Box>
      <img alt={mentor.name} className={styles.image} src={mentor.image} />
    </Box>
  );
};

export default Mentor;
