import React from 'react';
import cn from 'classnames';

import { ClockOutline } from '@lyearn/icons';
import { Typography } from '@lyearn/ui';

const ServiceDuration: React.FC<{ duration: string } & React.ComponentProps<typeof Typography>> = ({
  duration,
  className,
  ...rest
}) => {
  return (
    <Typography
      as="span"
      className={cn('flex items-center', className)}
      color="text-text-secondary"
      variant="heading-xxs"
      {...rest}>
      <ClockOutline className="h-20 w-20 fill-current me-4" />
      {duration}
    </Typography>
  );
};

export default ServiceDuration;
