import React, { FC } from 'react';
import cn from 'classnames';
import { CurriculumSection } from 'types/interfaces';
import { DefaultProps } from 'types/interfaces/DefaultProps';

import { Typography } from '@lyearn/ui';

export interface SectionProps extends DefaultProps {
  section: CurriculumSection;
}

const SectionTitle: FC<SectionProps> = (props) => {
  const { section, className } = props;
  const { title } = section;
  return (
    <Typography as="div" className={cn('flex text-text-primary', className)} variant="heading-xs">
      {title}
    </Typography>
  );
};

export default SectionTitle;
