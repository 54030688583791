import React from 'react';

import { Box, Typography } from '@lyearn/ui';

const ChildItemsCount: React.FC<{ count: number }> = ({ count }) => {
  return (
    <Box className="z-10 text-text-tertiary">
      <Typography variant="heading-xxs">{count === 1 ? `1 item` : `${count} items`}</Typography>
    </Box>
  );
};

export default ChildItemsCount;
