import React from 'react';
import cn from 'classnames';

import { Typography } from '@lyearn/ui';

import { verticalDivider } from './styles';

const Detail = ({ detail }: { detail: string }) => (
  <Typography as="div" className="heading-s xs:heading-xs text-text-primary">
    {detail}
  </Typography>
);

const VerticalDivider = () => <div className={cn(verticalDivider, 'mx-20 bg-icon-active')} />;

const ProductDetails = ({
  details,
  className,
}: {
  details: string[] | string;
  className: string;
}) => {
  let returnDetails = null;
  if (typeof details === 'string') {
    returnDetails = <Detail detail={details} />;
  } else if (Array.isArray(details)) {
    returnDetails = details.map((detail: string, index: number) => {
      if (index + 1 < details.length) {
        return (
          <React.Fragment key={index}>
            <Detail detail={detail} />
            <VerticalDivider />
          </React.Fragment>
        );
      } else {
        return <Detail key={index} detail={detail} />;
      }
    });
  }
  return (
    <Typography as="div" className={cn('flex flex-row items-center', className)}>
      {returnDetails}
    </Typography>
  );
};

export default ProductDetails;
