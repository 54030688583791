import React from 'react';
import cn from 'classnames';
import { UserProduct } from 'modules/product/types';
import { ProductContentTypeEnum, ProductTypeEnum } from 'types/schema';

import { Box, Stack } from '@lyearn/ui';

import ProductInfo3 from '../components/ProductInfo2';
import { productInfoHeaderWrapper } from '../styles';

type ProductInfoHeaderProps = {
  product: UserProduct;
  fetching: boolean;
  imageSize?: TSFixMe;
  showMoreInfo?: boolean;
};

/* for Antarakt Instance */
const ProductInfoHeader: React.FC<ProductInfoHeaderProps> = (props) => {
  const { product } = props;
  const { type, derivedFields } = product;
  const {
    purchaseInfo: { finalPrice },
  } = derivedFields;

  return (
    <Box
      className={cn(productInfoHeaderWrapper, 'flex flex-row xs:mt-6 xs:flex-wrap', {
        'mt-50': type === ProductTypeEnum.Course,
      })}>
      <Stack className="h-full w-1/2 me-64 xs:mx-auto xs:w-full">
        <Stack alignItems="center" direction="col" justifyContent="center">
          <img alt={'product'} className="mt-20 rounded-4" src="https://picsum.photos/280/220" />
          <img alt={'product'} className="mt-20 rounded-4" src="https://picsum.photos/280/220" />
          <img alt={'product'} className="mt-20 rounded-4" src="https://picsum.photos/280/220" />
        </Stack>
        <Stack alignItems="center" className="ms-20" direction="col" justifyContent="center">
          <img alt={'product'} className="mt-20 rounded-4" src="https://picsum.photos/280/220" />
          <img alt={'product'} className="mt-20 rounded-4" src="https://picsum.photos/280/220" />
        </Stack>
      </Stack>
      <Stack
        className={cn('flex w-1/2 flex-col justify-center xs:mt-12 xs:w-full', {
          'justify-center': !finalPrice,
        })}>
        <ProductInfo3 {...props} />
      </Stack>
    </Box>
  );
};

export default ProductInfoHeader;
