import React, { FunctionComponent } from 'react';
import cn from 'classnames';
import ProductMediaDisplay from 'types/enums/ProductMediaDisplay';
import { DefaultProps } from 'types/interfaces/DefaultProps';
import { MediaTypeEnum } from 'types/schema';

import { Box } from '@lyearn/ui';

import { imageWrapper } from './ProductCard/styles';

export interface ImageProps extends DefaultProps {
  lazyLoad?: boolean;
  displayType?: ProductMediaDisplay;
  type: MediaTypeEnum;
  url?: string;
}

const Image: FunctionComponent<ImageProps> = (props) => {
  const {
    url,
    className,
    children,
    lazyLoad = false,
    displayType = ProductMediaDisplay.Landscape,
  } = props;
  if (!url) {
    return null;
  }
  return (
    <Box className={className}>
      <div className={cn(imageWrapper(displayType), 'relative overflow-hidden')}>
        <div className="imageContainer flex items-center">
          <img alt="product" loading={lazyLoad ? 'lazy' : undefined} src={url} />
          {children}
        </div>
      </div>
    </Box>
  );
};

export default Image;
