import { css } from '@emotion/css';

const PAGINATION_BULLET_HEIGHT = 0.5;

export const paginationStyles = css`
  .swiper-pagination-bullet {
    width: 0.5rem;
    height: ${PAGINATION_BULLET_HEIGHT}rem;
    opacity: 1;
    transition: all;
    transition-duration: 200ms;
    background-color: var(--border-dark);

    &:only-child {
      display: none;
    }
  }

  .swiper-pagination-bullet-active {
    width: 1.6rem;
    border-radius: ${PAGINATION_BULLET_HEIGHT / 2}rem;
    background-color: var(--interactive-primary);
  }
`;
