//libs
import React from 'react';
import c from 'classnames';
import VideoWithPopup from 'components/VideoWithPopup';

//components
import { Box, Stack, Typography } from '@lyearn/ui';

//styles
import { container, imageContainer, leftCol, rightCol } from './styles';

const AboutSection = ({ layoutConfig }: TSFixMe) => {
  const { video, title, description, poster } = layoutConfig.props;

  return (
    <Stack
      className={c('mx-auto w-full bg-bg-primary py-128 px-56', container)}
      direction="col"
      id="about">
      <Typography className="xs:display-xl mb-40" color="text-text-primary" variant="display-xxl">
        {title}
      </Typography>
      <Stack className="w-full whitespace-pre-wrap xs:flex-col-reverse" justifyContent="between">
        <Box
          className={c('xs:w-full', leftCol)}
          dangerouslySetInnerHTML={{
            __html: `${description}`,
          }}
        />
        <Box className={c('relative h-full border xs:mb-48 xs:w-full', imageContainer, rightCol)}>
          <VideoWithPopup poster={poster} videoUrl={video} />
        </Box>
      </Stack>
    </Stack>
  );
};

export default AboutSection;
