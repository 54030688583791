import React, { useEffect } from 'react';
import cn from 'classnames';
import ProductCard from 'components/ProductCard';
import { useProductContext } from 'modules/context/Contexts/Product';
import { ChildUserProduct, UserProduct } from 'modules/product/types';

import { Box } from '@lyearn/ui';

import { productGrid } from './styles';

const CollectionOfferingGrid = () => {
  const { product } = useProductContext();

  return (
    <Box className={productGrid}>
      <Box className={cn('productCardWrapper justify-center')}>
        {(product as UserProduct).children?.map((cp: ChildUserProduct) => {
          return <ProductCard key={cp.id} product={cp} size="small" />;
        })}
      </Box>
    </Box>
  );
};

export default CollectionOfferingGrid;
