import React from 'react';
import { css } from '@emotion/css';
import cn from 'classnames';
import { ProductInfoGridComponent } from 'components/ProductInfoHeader';
import { useProductsContext } from 'modules/context/Contexts/Products';
import { ProductStatusEnum } from 'modules/product/types';

// import isEmpty from 'lodash/isEmpty';
import { Box } from '@lyearn/ui';

const gridWrapper = css`
  max-width: 116rem;
`;

const ProductGridV2 = () => {
  const { products, fetching } = useProductsContext();

  const productsList = products.filter((product) => product.status !== ProductStatusEnum.Unlisted);

  return (
    <>
      {
        <Box
          className={cn(
            'productCardWrapper mx-auto mb-50 mt-50 justify-center px-20 pb-10 pt-20',
            gridWrapper,
          )}>
          {productsList.map((product) => {
            return (
              <>
                <Box className="mb-50">
                  <ProductInfoGridComponent
                    fetching={fetching}
                    product={product}
                    showMoreInfo={true}
                  />
                </Box>
              </>
            );
          })}
        </Box>
      }
    </>
  );
};

export default ProductGridV2;
