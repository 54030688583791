import { css } from '@emotion/css';

const MAX_WIDTH = '144rem';

export const container = css`
  max-width: ${MAX_WIDTH};
  padding-top: 20rem;
  padding-inline-start: 12rem;
  padding-inline-end: 12rem;

  @media all and (max-width: 768px) {
    padding-inline-start: 2rem;
    padding-inline-end: 2rem;
  }
`;

export const playButtonStyles = css`
  height: 100% !important;
  width: 100% !important;
`;

export const playButtonContainer = css`
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 999px;
  color: #000;
`;

export const imageContainer = css`
  border-image: linear-gradient(
      135deg,
      rgba(231, 222, 201, 1),
      rgba(231, 222, 201, 0.23),
      rgba(231, 222, 201, 1)
    )
    1;
  :hover {
    .playBtn {
      background-color: var(--interactive-primary-hover);
    }
  }
`;

export const leftCol = css`
  width: 35.44%;
  @media all and (max-width: 768px) {
    width: 100%;
  }
`;
export const rightCol = css`
  width: 52.41%;
  @media all and (max-width: 768px) {
    width: 100%;
  }
`;
