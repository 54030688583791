import React, { FC } from 'react';
import cn from 'classnames';
import { DefaultProps } from 'types/interfaces/DefaultProps';

import { Typography } from '@lyearn/ui';

import { truncateStyles } from '../styles';

export interface DescriptionProps extends DefaultProps {
  description: string;
}

const Description: FC<DescriptionProps> = (props) => {
  const { description } = props;
  return (
    <Typography
      as="h3"
      className={cn('mt-16 text-text-secondary', truncateStyles(3))}
      variant="body-long-01">
      {description}
    </Typography>
  );
};

export default Description;
