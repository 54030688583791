import React from 'react';
import { css } from '@emotion/css';
import cn from 'classnames';
import { LayoutComponentUnflattened } from 'components/layoutPage/types';

import { Box } from '@lyearn/ui';

const container = css`
  max-width: 122.2rem;
  padding-bottom: 12rem;

  @media all and (max-width: 768px) {
    padding-inline-start: unset;
    padding: 0rem 2rem;
  }
`;

const ServiceBody: React.FC<
  React.ComponentProps<typeof Box> & { layoutConfig: LayoutComponentUnflattened }
> = ({ children, layoutConfig }) => {
  return (
    <Box className={container} {...layoutConfig.props}>
      {children}
    </Box>
  );
};

export default ServiceBody;
