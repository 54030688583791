import React from 'react';
import cn from 'classnames';

import { Box, Stack } from '@lyearn/ui';

import CollapsibleContent from '../CollapsibleContent';
import TestimonialItem from './components/TestimonialItem';
import { container, mb96 } from './styles';
import { Testimonial } from './types';

type Props = {
  testimonials: Testimonial[];
  title: string;
};

const Testimonials: React.FC<Props> = ({ title, testimonials }) => {
  return (
    <Stack className={cn('mx-auto px-20', container)} direction="col" id="testimonials">
      <Box className={cn('display-xxl xs:display-xl text-center text-text-primary', mb96)}>
        {title}
      </Box>
      <CollapsibleContent maxHeight="40rem">
        {/* This has custom layout for now, the design is to change after */}
        <Stack alignItems="stretch" className="xs:flex-col">
          <Stack className="flex-1 me-12 xs:me-0">
            <TestimonialItem testimonial={testimonials[0]} />
          </Stack>
          <Stack className="flex-1 me-12 xs:me-0" direction="col">
            <TestimonialItem className="mb-12" testimonial={testimonials[1]} />
            <TestimonialItem testimonial={testimonials[2]} />
          </Stack>
          <Stack alignItems="start" className="flex-1">
            <TestimonialItem fullHeight={false} testimonial={testimonials[3]} />
          </Stack>
        </Stack>
      </CollapsibleContent>
    </Stack>
  );
};

export default Testimonials;
