import React from 'react';
import cn from 'classnames';

import { Box, Button, Stack, Typography } from '@lyearn/ui';

import { container, descriptionStyle, innerContainer, leftCol, rightCol } from './styles';
import { TakeTestProps } from './types';

const TakeTest: React.FC<TakeTestProps> = ({ title, description, link, linkText, image }) => {
  return (
    <Box className={cn('bg-focus-primary', container)}>
      <Stack className={cn('mx-auto xs:flex-col', innerContainer)} direction="row">
        <Stack className={cn('xs:mb-48', leftCol)} direction="col">
          <Typography className="heading-xxxl xs:heading-xxl mb-28 text-text-white">
            {title}
          </Typography>
          <Box className={cn('mb-32 text-18 text-text-tertiary xs:text-16', descriptionStyle)}>
            {description}
          </Box>
          <Box className="flex-1" />
          <Box className="pb-16 xs:pb-0">
            <Button
              appearance="inverse"
              size="large"
              variant="filled"
              onClick={() => (window.location.href = link)}>
              {linkText}
            </Button>
          </Box>
        </Stack>
        <Stack alignItems="start" className={rightCol} justifyContent="center">
          <Box className="overflow-hidden rounded-16">
            <img alt={title} height="469" src={image} width="514" />
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default TakeTest;
