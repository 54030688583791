import React from 'react';
import cn from 'classnames';
import { DefaultProps } from 'types/interfaces/DefaultProps';

import { Container } from '@lyearn/ui';

export interface LabelProps extends DefaultProps {
  label?: string;
}

const Tag: React.FC<LabelProps> = (props) => {
  const { label, className } = props;
  return (
    <Container className={cn('xs:text-xs text-background uppercase', className)}>{label}</Container>
  );
};

export default Tag;
