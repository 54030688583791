import React from 'react';
import cn from 'classnames';
import CTAProduct from 'modules/product/components/CTA';
import { UserProduct } from 'modules/product/types';
import { GeneralSettingsType } from 'types/schema';

import { Box, Skeleton, Stack, Typography } from '@lyearn/ui';
import { TypographyColors } from '@lyearn/ui';

import ProductPrice from '../ProductPrice';
import ProductProgress from '../ProductProgress';
import ProductTitle from '../ProductTitle';
import ProductTypeLabel from '../ProductTypeLabel';
import { description } from '../styles';

/* For Antarakt instance */
type Props = {
  product: UserProduct;
  fetching: boolean;
  showMoreInfo?: boolean;
};

const ProductInfo3 = ({ product, fetching, showMoreInfo }: Props) => {
  const { generalSettings, userPerformance, purchased } = product;
  const { progress } = userPerformance || {};
  const { certificateOnCompletion = false } = (generalSettings as GeneralSettingsType) || {};
  const { finalPrice, currency } = product.derivedFields.purchaseInfo;

  return (
    <Stack direction="col">
      <ProductTypeLabel
        certificateOnCompletion={certificateOnCompletion}
        productType={product.type}
        titleColour={'text-interactive-primary' as TypographyColors}
        withBGStripe={true}
      />
      <ProductTitle
        className="mb-10 xs:mt-0"
        title={product?.name || ''}
        typographyVariant="display-xxxl"
      />
      <Box className="pb-14" />
      {fetching ? (
        <Skeleton animation="wave" height={25} variant="rectangular" width={150} />
      ) : purchased && progress ? (
        <ProductProgress progress={progress} />
      ) : null}
      <Typography as="div" className={cn('body-long-01 mb-10 text-text-secondary', description)}>
        {product.description}
      </Typography>

      <Box className="flex-1 pb-18" />
      <ProductPrice currency={currency} price={finalPrice} />
      <Box className="flex-1 pb-32 xs:pb-0" />
      <CTAProduct fetching={fetching} product={product} showMoreInfo={showMoreInfo} />
    </Stack>
  );
};

export default ProductInfo3;
