import React from 'react';
import User from 'components/User';
import { CourseContent_CourseContentType_Fragment } from 'data-op/fragments/generated/content';
import { useProductContext } from 'modules/context/Contexts/Product';
import transformInstructors from 'utils/transformInstructors';

import { Container, Typography } from '@lyearn/ui';

const Authors = () => {
  const { product } = useProductContext();
  const instructors = transformInstructors(
    (product.content as CourseContent_CourseContentType_Fragment)?.instructors || [],
  );

  if (!instructors || instructors.length < 1) {
    return null;
  }

  return (
    <Container className="pt-80">
      <Typography as="div" className="mb-20 text-text-primary" variant="heading-xl">
        Experts
      </Typography>
      <Container className="flex xs:flex-wrap">
        {instructors.map((instructor) => (
          <User key={instructor.name} className="me-30 xs:mb-20 xs:me-0" userDetails={instructor} />
        ))}
      </Container>
    </Container>
  );
};

export default Authors;
