import { css } from '@emotion/css';

const MAX_WIDTH = '120rem';

export const container = css`
  max-width: ${MAX_WIDTH};
  padding-top: 20rem;
`;

export const detialsContianter = css`
  transform: translateY(50%);
`;

export const mainMentorName = css`
  font-family: Playfair Display;
  font-weight: bold;
  font-size: 3.2rem;
  line-height: 4.3rem;
  letter-spacing: -0.02em;
`;

export const mainMentorTitle = css`
  color: #ada797;
  font-family: Playfair Display;
  font-size: 3.2rem;
  line-height: 4.3rem;
  letter-spacing: -0.02em;
`;

export const coMentorName = css`
  font-family: Playfair Display;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 3.2rem;
  letter-spacing: -0.02em;
`;

export const coMentorTitle = css`
  color: #ada797;
  font-family: Playfair Display;
  font-size: 2rem;
  line-height: 2.7rem;
  letter-spacing: -0.02em;
`;

export const mentorImage = css`
  max-width: 100%;
  max-height: 44rem;
`;

export const emDashStyles = css`
  background: #c4c4c4;
`;
