import { css } from '@emotion/css';

export const containerStyles = css`
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.0086472) 6.67%,
    rgba(0, 0, 0, 0.03551) 13.33%,
    rgba(0, 0, 0, 0.0816599) 20%,
    rgba(0, 0, 0, 0.147411) 26.67%,
    rgba(0, 0, 0, 0.231775) 33.33%,
    rgba(0, 0, 0, 0.331884) 40%,
    rgba(0, 0, 0, 0.442691) 46.67%,
    rgba(0, 0, 0, 0.557309) 53.33%,
    rgba(0, 0, 0, 0.668116) 60%,
    rgba(0, 0, 0, 0.768225) 66.67%,
    rgba(0, 0, 0, 0.852589) 73.33%,
    rgba(0, 0, 0, 0.91834) 80%,
    rgba(0, 0, 0, 0.96449) 86.67%,
    rgba(0, 0, 0, 0.991353) 93.33%,
    #000000 100%
  );
`;

export const heroTitle = css`
  left: 50%;
  transform: translateX(-50%);
  bottom: 20rem;
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
`;

export const soundBtn = css`
  bottom: 14rem;
`;

export const buttonWrapper = css`
  bottom: 5.6rem;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20rem;
`;

export const header = css`
  height: 25.8rem;
  max-width: 118rem;
  @media (max-width: 1670px) {
    max-width: 112rem;
  }
  @media (max-width: 1024px) {
    max-width: 90.4rem;
  }
  @media (max-width: 768px) {
    max-width: 67.2rem;
  }
  @media (max-width: 600px) {
    max-width: 45rem;
    height: 28rem;
  }
  @media (max-width: 414px) {
    max-width: 37.4rem;
    /* height: 21.7rem; */
  }
  overflow: hidden;
  margin: 0 auto;
`;

export const headerFullScreen = css`
  /* height: 25.8rem; */
  max-width: none;
  width: auto;
  overflow: hidden;
  height: 40rem;
  margin: 0px -10px;
  @media (max-width: 1024px) {
    height: 35rem;
  }
  @media (max-width: 600px) {
    height: 25rem;
  }
  @media (max-width: 414px) {
    /* height: 21.7rem; */
  }
`;

export const bgImage = (url: string) => css`
  background-image: ${`url(${url})`};
  background-size: cover;
  filter: brightness(80%);
  width: auto;
  left: 0rem;
  right: 0rem;
  background-position: center;
`;

export const subTitle = css`
  width: 80%;
  max-width: 63rem;
  font-size: 2rem;
  line-height: 3.2rem;
  /* display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden; */
`;

export const gradient = css`
  background: linear-gradient(269.81deg, rgba(46, 28, 11, 0) 0.16%, #1b1006 73.88%);
  backdrop-filter: blur(9px);
`;
