import React from 'react';
import cn from 'classnames';

import { Typography } from '@lyearn/ui';

import { truncateStyles } from '../styles';

const Name: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Typography
      as="h2"
      className={cn('mt-6 text-text-primary', truncateStyles(3))}
      variant="heading-m">
      {children}
    </Typography>
  );
};

export default Name;
