import React from 'react';
import c from 'classnames';

import { CircleCheckMarkOutline, ClockOutline } from '@lyearn/icons';
import { Box, Typography } from '@lyearn/ui';

import { iconStyle } from './styles';

const ProductProgress = ({ progress }: { progress: number }) => {
  let icon = <ClockOutline className={c(iconStyle, 'h-24 w-24 fill-current text-focus-primary')} />;
  if (progress === 100) {
    icon = (
      <CircleCheckMarkOutline
        className={c(iconStyle, 'h-24 w-24 fill-current text-focus-primary')}
      />
    );
  }
  return (
    <Box className="mb-26 flex items-center space-s-6">
      {icon}
      <Typography as="div" className="heading-s xs:heading-xs text-text-primary ms-4">
        {progress}% Complete
      </Typography>
    </Box>
  );
};

export default ProductProgress;
