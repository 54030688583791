import React from 'react';
import cn from 'classnames';
import Price from 'components/Price';
import { ChildUserProduct, GenericProduct } from 'modules/product/types';
import Link from 'next/link';
import { ProductTypeEnum } from 'types/schema';

import { Box, Stack } from '@lyearn/ui';

import Card from './components/Card';
import CardAction from './components/CardAction';
import CardBody from './components/CardBody';
import CardBodyBottom from './components/CardBodyBottom';
import CardBodyTop from './components/CardBodyTop';
import CertificateTag from './components/CertificateTag';
import ChildItemsCount from './components/ChildItemsCount';
import Collection from './components/Collection';
import CompletedLabel from './components/CompletedLabel';
import Description from './components/Description';
import Duration from './components/Duration';
import Media from './components/Media';
import MediaOverlay from './components/MediaOverlay';
import Name from './components/Name';
import Positioned from './components/Positioned';
import Progress from './components/Progress';
import Tag from './components/Tag';
import { productCard, tagPosition } from './styles';

export interface ProductCardProps {
  product: GenericProduct;
  size?: 'small' | 'medium';
  children?: ChildUserProduct[];
}

const ProductCard: React.FC<ProductCardProps> = ({ product, size = 'medium' }) => {
  const {
    id,
    media,
    type,
    isChildProduct,
    parentProduct,
    name,
    description,
    purchased,
    SEOSettings,
    generalSettings,
    userPerformance,
    productIds,
    contentDuration,
    derivedFields,
  } = product;

  const { purchaseInfo, completed } = derivedFields;

  const progress = userPerformance?.progress;

  const href = isChildProduct
    ? `/${parentProduct?.SEOSettings?.slug}/${id}`
    : `/${SEOSettings?.slug}`;

  const dur = contentDuration;
  const numberOfItems = productIds.length;
  const isCollection = type === ProductTypeEnum.Collection;
  const tag = generalSettings?.label
    ? generalSettings?.label
    : generalSettings?.isBestSeller
    ? 'Best Seller'
    : '';

  return (
    <Link className="flex" href={href} shallow={false}>
      {/* https://github.com/vercel/next.js/issues/5533 */}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Card
        className={cn(
          'group relative z-10 flex w-full cursor-pointer flex-col rounded-16 bg-border-dark hover:bg-interactive-primary hover:shadow-04',
          productCard(isCollection, size),
        )}>
        {isCollection ? <Collection items={numberOfItems} /> : null}
        <Media media={media}>
          <MediaOverlay />
          {!purchased && tag ? (
            <Positioned position="top-right">
              <Tag text={tag} />
            </Positioned>
          ) : null}
          {generalSettings?.certificateOnCompletion ? (
            <Positioned position="top-left">
              <CertificateTag />
            </Positioned>
          ) : null}
          {isCollection ? (
            <Positioned position="bottom-left">
              <ChildItemsCount count={numberOfItems} />
            </Positioned>
          ) : null}
          {!purchased ? (
            <Positioned position="bottom-right">
              <Duration duration={dur} />
            </Positioned>
          ) : null}
        </Media>
        <CardBody
          className={cn(
            'bg-background relative z-10 flex flex-1 flex-col justify-between rounded-b-16 bg-bg-primary px-24 pt-20 pb-22',
            isCollection
              ? 'collection border-b border-solid border-border-dark group-hover:border-interactive-primary'
              : 'border-none',
          )}>
          <CardBodyTop className="pb-50">
            <Name>{name}</Name>
            {!purchased ? <Description description={description || ''} /> : null}
          </CardBodyTop>
          <CardBodyBottom>
            {purchased && !completed ? <Progress percentage={progress || 0} /> : null}
            <Stack className="mt-auto w-full items-center justify-between">
              <CardAction product={product} />
              <Box>
                {progress === 100 ? <CompletedLabel /> : null}
                {!purchased && !isChildProduct ? (
                  <Price purchaseInfo={purchaseInfo} size="heading-xs" />
                ) : null}
              </Box>
            </Stack>
          </CardBodyBottom>
        </CardBody>
      </Card>
    </Link>
  );
};

export default ProductCard;
