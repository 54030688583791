import React from 'react';
import cn from 'classnames';
import Link from 'next/link';

import { ArrowForward } from '@lyearn/icons';
import { Box, LinkButton, Stack, Typography } from '@lyearn/ui';

import { container, titleStyle } from './styles';
import { AboutUsProps } from './types';

const AboutUs = ({ label, title, image, description, linkText, link }: AboutUsProps) => {
  return (
    <Stack className={cn('mx-auto px-20', container)} direction="col">
      <Typography className="mb-14" color="text-text-placeholder" variant="label-02">
        {label}
      </Typography>
      <Typography
        as="h2"
        className={cn('heading-xxxl xs:heading-xxl text-text-primary', titleStyle)}>
        {title}
      </Typography>
      <Stack className="xs:flex-col" direction="row">
        <Box className="about-us-image order-1 flex items-start xs:order-2 xs:justify-center">
          <img alt={title} src={image} />
        </Box>
        <Stack className="about-us-content order-2 xs:order-1 xs:mb-50" direction="col">
          <Box
            className="prose prose-xl text-18 text-text-paragraph"
            component="h3"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <Box className="pt-24">
            <a href={link} rel="noreferrer" target="_blank">
              <LinkButton
                // classes={{ label: 'text-16' }}
                color="external"
                endIcon={<ArrowForward className="rtl:rotate-180" />}>
                {linkText}
              </LinkButton>
            </a>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AboutUs;
