import { css } from '@emotion/css';

export const container = css`
  padding-top: 10rem;
  padding-bottom: 9.8rem;
  max-width: 116rem;

  .about-us-image {
    width: 37.14%;
    margin-inline-end: 7.5%;

    max-width: 100%;
    height: auto;

    @media all and (max-width: 768px) {
      width: 100%;
      margin-inline-end: 0;
    }
  }

  .about-us-content {
    width: 46.4%;
    @media all and (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const titleStyle = css`
  max-width: 74.2rem;
  margin-bottom: 5.6rem;
`;

export const customLinkColor = css`
  color: var(--link-01) !important;
  display: block;
`;
