import { useState } from 'react';

export function useToggle(
  initialValue: boolean,
): [boolean, () => void, (nextValue: boolean) => void] {
  const [value, setValue] = useState(initialValue);
  const toggle = () => {
    setValue((prevValue) => !prevValue);
  };
  return [value, toggle, setValue];
}
