import React from 'react';
import { css } from '@emotion/css';
import cn from 'classnames';

import { Container } from '@lyearn/ui';

const divider = (margin: number = 0) => css`
  height: 0.1rem;
  width: 100%;
  background: var(--accents-2);
  ${margin ? `margin: ${margin}px 0px` : null}
`;

const Divider = ({ layoutConfig }: TSFixMe) => {
  const { margin, className = '' } = layoutConfig.props;
  return <Container className={cn(divider(margin), className)} />;
};

export default Divider;
