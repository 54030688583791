import React from 'react';
import cn from 'classnames';
import { LayoutComponentUnflattened } from 'components/layoutPage/types';
import { useProductContext } from 'modules/context/Contexts/Product';

import { Box, Container as ContainerComponent, Typography } from '@lyearn/ui';

import Container from './Container';
import Item from './Item';
import SectionTitle from './SectionTitle';
import { curriculumContainer, curriculumWrapper } from './styles';

const Curriculum = ({ layoutConfig }: { layoutConfig: LayoutComponentUnflattened }) => {
  const { product } = useProductContext();

  const sections = product.content.curriculum;

  return (
    <ContainerComponent
      className={cn(
        'md:me-64 sticky flex-shrink-0 me-80 xs:static xs:mt-26 xs:me-0',
        curriculumWrapper,
      )}>
      <Typography as="div" className="heading-xl lg:heading-l mb-30 text-text-primary">
        {layoutConfig.props.title}
      </Typography>
      <Container
        className={cn(
          curriculumContainer,
          'overflow-auto py-20 px-26 text-text-primary xs:px-20 xs:py-16',
        )}>
        {sections.map((section: TSFixMe, index: number) => (
          <Box key={index}>
            {sections.length > 1 ? (
              <>
                <SectionTitle
                  key={'section-' + index}
                  className={cn('mb-6', index !== 0 && 'mt-40')}
                  section={section}
                />
                <Box key={index} className="mt-6 mb-20 w-auto border border-solid border-divider" />
              </>
            ) : null}
            {section.items.map((item: TSFixMe, itemIndex: number) => (
              <Item key={itemIndex} className="mb-26" item={item} />
            ))}
          </Box>
        ))}
      </Container>
    </ContainerComponent>
  );
};

export default Curriculum;
