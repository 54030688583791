import cloneDeep from 'lodash/cloneDeep';

import { COMPONENT_REF } from '../layoutComponents';
import {
  LayoutComponent,
  LayoutComponentUnflattened,
  LayoutConfig,
  LayoutConfigStructured,
} from '../types';

/* 
Input :

LayoutConfig {
  id: string;
  children: Array<string>;
  type: string;
  components: {
      id: string;
      componentRef: string;
      props?: object;
      childComponentRefs?: Array<string>;
      children?: any;
  }
}

Output:  
  interface LayoutConfigStructured {
    id: string;
    children: Array<string>;
    type: string;
    components: {
      id: string;
      props?: object;
      children: any[];
      component: any;
    };
  }

 */

const populateComponentRef = (componentConfig: LayoutComponent) => {
  // const newComponentConfig = cloneDeep(componentConfig);
  const component = COMPONENT_REF[componentConfig.componentRef as keyof typeof COMPONENT_REF];
  if (!component) {
    console.log('COMPONENT_REF: ', COMPONENT_REF, componentConfig, componentConfig.componentRef);

    throw Error('ComponentRef not recognised from config!');
  }
  (componentConfig as LayoutComponentUnflattened).component = component;
  // delete componentConfig.componentRef;
  return componentConfig as LayoutComponentUnflattened;
};

const processComponent = (components: LayoutConfig['components'], key: string) => {
  const component = populateComponentRef(components[key]); //
  const componentChildComponentRefs = component && component.childComponentRefs;
  if (componentChildComponentRefs && componentChildComponentRefs.length) {
    component.children = [];
    componentChildComponentRefs.forEach((childComponentKey) => {
      if (
        components[childComponentKey]?.childComponentRefs &&
        components[childComponentKey]?.childComponentRefs?.length
      ) {
        // recursive
        processComponent(components, childComponentKey);
      }
      component.children.push(
        populateComponentRef(components[childComponentKey]), //
      );
      delete components[childComponentKey];
    });
  }
  delete component.childComponentRefs;
};

const restructureAndPopulateConfig = (layoutConfig: LayoutConfig): LayoutConfigStructured => {
  const structuredConfig = cloneDeep(layoutConfig);
  const components = structuredConfig.components;

  /* 
  looping through components of layout
  adding {childComponentsRefs} in component if it has 
    {childComponents} 
  in config, 
  and Removing 
    {childComponentRefs},
  from config
  */
  Object.keys(components).forEach((key) => {
    if (!components[key]) {
      return;
    }
    processComponent(components, key);
  });

  return structuredConfig as LayoutConfigStructured;
};

export default restructureAndPopulateConfig;
