import React from 'react';
import cn from 'classnames';

import { Box } from '@lyearn/ui';

import TestimonyItem from './components/TestimonialItem';
import { container, titleStyle } from './styles';
import { TestimonialsProps } from './types';

const Testimonials = ({ title, style, testimonials }: TestimonialsProps) => {
  return (
    <Box className="px-20" style={style}>
      <Box className={cn('mx-auto flex flex-col', container)}>
        <p className={cn('mb-6 text-28 font-bold', titleStyle)}>{title}</p>
        <Box className="flex flex-row items-stretch pt-50 sm:flex-col sm:items-center xs:flex-col xs:items-center">
          {testimonials.map((testimonial) => (
            <Box
              key={testimonial.user?.name}
              className="flex me-42 sm:mb-32 sm:me-0 xs:mb-32 xs:me-0">
              <TestimonyItem {...testimonial} />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Testimonials;
