import React from 'react';
import { css } from '@emotion/css';
import cn from 'classnames';
import User from 'components/User';
import { useServiceContext } from 'modules/service/context/ServiceContext';

import { Box, Typography } from '@lyearn/ui';

const divider = css`
  background: #242424;
  height: 0.1rem;
  margin: 5.6rem 0;
  max-width: 60rem;
`;

const userStyle = css`
  & .image-container {
    height: 4.4rem !important;
    width: 4.4rem !important;
    border-radius: 0.4rem !important;
  }
  & .image-container > div {
    border-radius: 0.4rem !important;
  }
  & .user-designation {
    margin-top: 0.3rem !important;
  }
`;

const container = css`
  .prose {
    max-width: 65.5rem;
  }
  .prose h2,
  .prose h3,
  .prose h4 {
    color: var(--text-primary);
  }
`;

const EventExpert = ({ title }: { title: string }) => {
  const { service } = useServiceContext();
  const { expert } = service;

  return (
    <>
      <Box className={divider} />
      <Box className="mb-30">
        <Typography as="p" className="mb-32" color="text-text-primary" variant="display-m">
          {title}
        </Typography>
        <Box className="flex xs:flex-wrap">
          <User className={cn('me-30 xs:mb-20 xs:me-0', userStyle)} userDetails={expert} />
        </Box>
      </Box>
      <Box className={container}>
        <Box
          className="prose prose-2xl text-text-paragraph"
          dangerouslySetInnerHTML={{ __html: expert.expertDescription }}
        />
      </Box>
    </>
  );
};

export default EventExpert;
