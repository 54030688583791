import React from 'react';
import { css } from '@emotion/css';
import cn from 'classnames';
import { Service } from 'types/interfaces/SiteConfig';

import { Box } from '@lyearn/ui';

const gradient = css`
  background: linear-gradient(111.69deg, rgba(0, 0, 0, 0.86) 0%, rgba(0, 0, 0, 0) 91.88%);
`;

const ServiceMedia = ({ service }: { service: Service }) => {
  return (
    <Box className="relative overflow-hidden rounded-4">
      <Box className={cn('absolute inset-0', gradient)} />
      <img alt={service.title} height="348" src={service.image} width="530.29" />
    </Box>
  );
};

export default ServiceMedia;
