// libs
import React, { useCallback } from 'react';
import { MouseEventHandler } from 'react';
import { css } from '@emotion/css';
import c from 'classnames';

import { Box } from '@lyearn/ui';

// components
import Item from './Item';

const topStyle = (top: number) => css`
  top: ${top}rem;
`;

export type NavItem = {
  id: string;
  name: string;
  onClick?: (e: any) => void | undefined;
};

interface SideNavProps {
  activeId: string;
  className?: string;
  items: NavItem[];
  onClick: Function;
  sticky?: boolean;
  top?: number; // in rem
}

const SideNav = (props: SideNavProps) => {
  const { activeId, className, items, onClick, sticky = false, top = 0 } = props;

  const handleClick = useCallback(
    (event: any) => {
      const id = event?.currentTarget?.getAttribute('data-id');
      if (id) {
        onClick(id);
      }
    },
    [onClick],
  );

  return (
    <Box className={c('w-full', className)}>
      <Box className={c(sticky && 'sticky', topStyle(top))} component="nav">
        {items.map((item) => {
          return (
            <Item key={item.id} active={activeId === item.id} onClick={handleClick} {...item} />
          );
        })}
      </Box>
    </Box>
  );
};

export default SideNav;
