import { css } from '@emotion/css';

export const curriculum = css`
  width: 42.4rem;
  border-radius: 1.2rem;
  @media (max-width: 1024px) {
    width: 100%;
    max-width: 35rem;
  }
`;

export const curriculumContainer = css`
  @media (min-width: 767px) {
    height: calc(100vh - 180px);
  }
`;

export const curriculumWrapper = css`
  @media (min-width: 767px) {
    max-height: calc(100vh - 100px);
    top: 90px;
  }
`;
