import { css } from '@emotion/css';

export const container = css`
  max-width: 85rem;
  padding-top: 15rem;
  margin-top: 15rem;

  @media all and (max-width: 768px) {
    margin-top: 0rem;
  }
`;

export const mb96 = css`
  margin-bottom: 9.6rem;
`;

/* custom colours for now, token mapping remaining from design */
export const userName = css`
  color: #fff;
`;

export const roleText = css`
  color: #666666;
`;
