import { css } from '@emotion/css';

export const container = css`
  padding: 10rem 2rem;
`;

export const innerContainer = css`
  max-width: 112rem;
`;

export const leftCol = css`
  width: 43.25%;
  margin-inline-end: 14.12%;

  @media all and (max-width: 768px) {
    width: 100%;
    margin-inline-end: 0;
  }
`;

export const rightCol = css`
  width: 48.3%;
  @media all and (max-width: 768px) {
    width: 100%;
  }
`;

export const descriptionStyle = css`
  max-width: 36ch;
  @media all and (max-width: 768px) {
    max-width: none;
  }
`;
