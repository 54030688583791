import React from 'react';
import cn from 'classnames';

import { Typography } from '@lyearn/ui';
import { TypographyVariants } from '@lyearn/ui';

const ProductTitle = ({
  title,
  className,
  typographyVariant,
}: {
  title: string;
  className: string;
  typographyVariant?: TypographyVariants;
}) => {
  return (
    <Typography
      as="h1"
      className={cn('xs:heading-m text-text-primary', className)}
      variant={typographyVariant ?? 'heading-xl'}>
      {title}
    </Typography>
  );
};

export default ProductTitle;
