import React from 'react';
import { css } from '@emotion/css';
import cn from 'classnames';

import { Box } from '@lyearn/ui';

export const mediaOverlay = css`
  background: rgba(0, 0, 0, 0.3);
`;

const MediaOverlay: React.FC<{ className?: string }> = ({ className }) => {
  return <Box className={cn('absolute inset-0 z-20', mediaOverlay, className)} />;
};

export default MediaOverlay;
