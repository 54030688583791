import React, { FC } from 'react';
import cn from 'classnames';
import { CurriculumItem } from 'types/interfaces';
import { DefaultProps } from 'types/interfaces/DefaultProps';

import { Box, Typography } from '@lyearn/ui';

import Icon from '../Icon';

export interface ItemProps extends DefaultProps {
  item: CurriculumItem;
}

const Item: FC<ItemProps> = (props) => {
  const { item, className } = props;
  const { title, icon } = item;
  return (
    <Box className={cn('flex items-center', className)}>
      <span className="text-icon-active">
        <Icon className="h-20 w-20 fill-current me-16" type={icon} />
      </span>
      <Typography className="text-text-primary" variant="body-short-01">
        {title}
      </Typography>
    </Box>
  );
};

export default Item;
