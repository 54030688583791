// libs
import React from 'react';
import c from 'classnames';

import { Box, Typography } from '@lyearn/ui';

import { NavItem } from '.';

const Item: React.FC<NavItem & { active: boolean }> = ({ name, active, onClick, id }) => {
  return (
    <Box
      className={c(
        'py-12 ps-24 border-s-4 hover:bg-bg-secondary',
        active ? 'borderlink-external rounded-4 bg-bg-secondary' : 'border-transparent',
      )}
      data-id={id}
      role="button"
      onClick={onClick}>
      <Typography
        color={active ? 'text-text-primary' : 'text-text-placeholder'}
        variant="heading-xs">
        {name}
      </Typography>
    </Box>
  );
};

export default Item;
