import React from 'react';

import { ArrowForward } from '@lyearn/icons';
import { LinkButton } from '@lyearn/ui';

import { ProductCardProps } from '..';
import Resume from './Resume';

const CardAction = ({ product }: ProductCardProps) => {
  const {
    purchased,
    isChildProduct,
    derivedFields: { completed },
    userPerformance,
    generalSettings,
    type,
  } = product;

  if (purchased) {
    if (completed || userPerformance?.progress === 100) {
      if (!isChildProduct && generalSettings?.certificateOnCompletion) {
        return (
          <LinkButton color="external" endIcon={<ArrowForward className="rtl:rotate-180" />}>
            View certificate
          </LinkButton>
        );
      } else {
        return (
          <LinkButton color="external" endIcon={<ArrowForward className="rtl:rotate-180" />}>
            Review learning
          </LinkButton>
        );
      }
    } else {
      return <Resume />;
    }
  }

  return (
    <LinkButton color="external" endIcon={<ArrowForward className="rtl:rotate-180" />}>
      View {type.toLowerCase()}
    </LinkButton>
  );
};

export default CardAction;
