import { css } from '@emotion/css';

import { header, subTitle } from './styles';

export const rightImageContainer = css`
  max-width: 37.5%;
  min-width: 240px;
  @media (min-width: 1440px) {
    max-width: 44%;
    margin-inline-end: -30px;
  }
  @media (max-width: 767px) {
    max-width: 65%;
  }
`;

export const headerHero2 = css`
  ${header}
  height: 65vh !important;
  overflow: visible;
  @media (max-width: 767px) {
    height: auto !important;
  }
`;

export const subTitleHero2 = css`
  ${subTitle};
  width: auto;
  max-width: 39ch;
`;
