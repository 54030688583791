import 'swiper/css';
import 'swiper/css/pagination';

import React, { useMemo, useState } from 'react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import useRect from '@lyearn/core/hooks/useRect';
import { Box, Stack } from '@lyearn/ui';

import { renderNavigationControls, renderPaginationControls } from './renderers';
import { CarouselComposition, CarouselProps } from './types';

SwiperCore.use([Navigation, Pagination, Autoplay]);

const Carousel: React.FC<React.PropsWithChildren<CarouselProps>> & CarouselComposition = (
  props,
) => {
  const { children, className, navigation = false, pagination = false, ...rest } = props;
  const { ref, width } = useRect<HTMLDivElement>();
  const [paginationEl, setPaginationEl] = useState<HTMLDivElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLButtonElement | null>(null);
  const [prevEl, setPrevEl] = useState<HTMLButtonElement | null>(null);

  const paginationOptions = useMemo(() => {
    if (!pagination) {
      return false;
    }

    return {
      clickable: true,
      el: paginationEl,
    };
  }, [pagination, paginationEl]);

  const navigationOptions = useMemo(() => {
    if (!navigation) {
      return false;
    }

    return {
      nextEl,
      prevEl,
      disabledClass: 'hidden',
    };
  }, [navigation, nextEl, prevEl]);

  return (
    <Stack ref={ref} className={className} direction="col" gapY={16}>
      <Box className="relative w-full">
        <Swiper
          className="swiper-container"
          navigation={navigationOptions}
          pagination={paginationOptions}
          threshold={2}
          width={width}
          {...rest}>
          {children}
        </Swiper>
        {navigation ? renderNavigationControls({ setPrevEl, setNextEl }) : null}
      </Box>
      {pagination ? renderPaginationControls({ setPaginationEl }) : null}
    </Stack>
  );
};

Carousel.Slide = SwiperSlide;

export default Carousel;
