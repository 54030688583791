import React, { useRef } from 'react';
import cn from 'classnames';

import { Box, Button, Stack, Typography } from '@lyearn/ui';

import GroupedImages from './GroupedImages';
import { container, innerContainer, leftCol, rightCol, spacer } from './styles';
import { Hero5Props } from './types';

const Hero5: React.FC<Hero5Props> = ({ title, description, skipToContentText, images }) => {
  const heroRef = useRef<any>(null);
  const scrollToEndOfHero = () => {
    if (heroRef && heroRef.current) {
      const rect = heroRef?.current?.getBoundingClientRect?.();
      const top = rect.x + rect.height - 60;
      window.scrollTo({
        top,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Box ref={heroRef} className={container}>
      <Stack className={cn('mx-auto xs:flex-col', innerContainer)} direction="row">
        <Stack className={cn('order-1 xs:order-2', leftCol)} direction="col">
          <Box
            className="heading-xxxl xs:heading-xxl mb-32 text-text-white xs:mb-16 xs:text-center"
            component="h2">
            {title}
          </Box>
          <Box className="mb-38 text-20 text-text-white xs:text-center xs:text-16" component="h3">
            {description}
          </Box>
          <Box className={cn('flex-1', spacer)} />
          <Box className="pb-10 xs:flex xs:justify-center">
            <Button appearance="inverse" size="large" variant="filled" onClick={scrollToEndOfHero}>
              {skipToContentText}
            </Button>
          </Box>
        </Stack>
        <Stack
          alignItems="center"
          className={cn('order-2 xs:order-1 xs:mb-48 xs:justify-center', rightCol)}>
          <GroupedImages images={images} />
        </Stack>
      </Stack>
    </Box>
  );
};

export { Hero5 };
export default Hero5;
