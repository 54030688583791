import React from 'react';

import { Box } from '@lyearn/ui';

const CardBodyBottom: React.FC<React.ComponentProps<typeof Box>> = ({
  className = 'flex flex-col',
  children,
  ...rest
}) => {
  return (
    <Box className={className} {...rest}>
      {children}
    </Box>
  );
};

export default CardBodyBottom;
