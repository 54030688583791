import React from 'react';
import cn from 'classnames';
import { DefaultProps } from 'types/interfaces/DefaultProps';
import { DeprecatedMediaType, Media, MediaTypeEnum } from 'types/schema';

import Label from './Label';
import { borderRadius, mediaContainer, mediaWrapper } from './styles';
import { MediaWithLabelProps } from './types';

interface MediaProps extends MediaWithLabelProps, DefaultProps {
  media: DeprecatedMediaType[];
}

const MediaWithLabel = ({
  mediaUrl,
  mediaType,
  className,
  label,
  imageSize = 'large',
  media,
}: MediaProps) => {
  const videoAvailable = media?.find((mediaItem) => mediaItem.type === MediaTypeEnum.Video);
  const poster =
    (videoAvailable && videoAvailable.resolutions && videoAvailable.resolutions[0]?.url) ||
    undefined;
  return (
    <div className={cn(mediaWrapper(imageSize), 'relative overflow-hidden', className)}>
      <div className={cn(mediaContainer, 'flex items-center')}>
        {!videoAvailable && mediaType === MediaTypeEnum.Image ? (
          <img
            alt="product"
            src={
              mediaUrl ||
              'https://d7jx2atmwmj9r.cloudfront.net/uploads/images/Research_1595168475814.jpg'
            }
          />
        ) : (
          videoAvailable && (
            <video
              className={cn(
                'block h-full w-full border border-solid border-border-dark object-cover outline-none',
                borderRadius,
              )}
              controls={true}
              poster={poster}
              src={videoAvailable.url}
            />
          )
        )}
        {label ? (
          <Label
            className="bg-foreground text-background absolute top-0 mt-8 flex h-16 w-auto items-center justify-center px-6 ms-8 start-0"
            label={label}
          />
        ) : null}
      </div>
    </div>
  );
};

export default MediaWithLabel;
